<script>
  import { Button } from '@client/components/button';
  import { Loader } from '@client/components/loader';
  import { useQuery } from '@sveltestack/svelte-query/svelte';
  import { format } from 'svelte-i18n';

  import { FilterBar } from '@client/components/filter-bar';
  import { Grid } from '@client/components/grid';
  import { Pagination } from '@client/components/pagination';
  import { SearchFilter } from '@client/components/filters/search';

  import { TagsService } from '@client/services/tags';

  import { QueryParams } from '@client/utils/query-params';

  import { appStore } from '@client/stores/app';
  import { tagsSettingsStore } from '@client/stores/page-settings';

  import { EditTagModal, DeleteTagModal } from './modal';

  import { ActionsCell, TagCell } from './cell-formatters';

  let tags = [];
  let totalRecords = 0;
  let totalPages = 0;
  let uniqueTagLabel = true;
  let showModal = false;
  let showConfirmModal = false;
  let isEditing = false;
  let isUpdating = false;
  let showFilters =
    $appStore.isMobile && $tagsSettingsStore.showFilters !== undefined ? $tagsSettingsStore.showFilters : true;

  let tag = {
    label: '',
    description: '',
    bg: '#b8c9ff',
    color: '#585da7',
    links: []
  };

  const getParamValue = (property, defaultValue, formatter) => {
    const value = QueryParams.get(property) || $tagsSettingsStore.params[property] || defaultValue;

    if (formatter) {
      return formatter(value);
    }

    return value;
  };

  let queryParams = {
    field: getParamValue('field', 'label'),
    limit: getParamValue('perpage', 25, Number),
    order: getParamValue('order', -1),
    page: getParamValue('page', 0, (page) => Math.max(0, page - 1)),
    search: getParamValue('search', '')
  };

  const onChangePage = (page) => {
    $tagsSettingsStore.params = { ...queryParams, page };
  };

  const onChangeRecords = (limit) => {
    $tagsSettingsStore.params = { ...queryParams, limit };
  };

  const updateQueryParams = () => {
    queryParams = { ...queryParams };
    $tagsSettingsStore.params = queryParams;
  };

  const remove = (selected) => {
    tag = selected;
    showConfirmModal = true;
  };

  const edit = (selected) => {
    showModal = true;
    tag = selected;
    isEditing = true;
  };

  let columns = [
    {
      id: 'label',
      sortKey: 'label',
      label: $format('label.LABEL'),
      renderComponent: TagCell
    },
    {
      id: 'description',
      sortable: false,
      label: $format('label.DESCRIPTION'),
      renderValue: ({ description }) => description
    },
    // {
    //   id: 'urls',
    //   sortable: false,
    //   label: $format('label.URLS'),
    //   renderValue: ({ urls }) => (urls || []).join(', ')
    // },
    // {
    //   id: 'links',
    //   sortable: false,
    //   label: $format('label.LINKS'),
    //   renderValue: ({ links }) => links.join(', ')
    // },
    {
      id: 'actions',
      label: '',
      renderComponent: {
        component: ActionsCell,
        props: {
          edit,
          remove
        }
      }
    }
  ];

  const onColumnSort = (field, order) => {
    $tagsSettingsStore.params = { ...queryParams, field, order };
  };

  const queryFn = async () => {
    const { records, pagination } = await TagsService.getAll(queryParams);

    tags = records;
    totalRecords = pagination.total;
    totalPages = pagination.pages;
    tags = [...tags];

    return tags;
  };

  const query = useQuery(['tags', queryParams], queryFn, { keepPreviousData: true });

  const create = () => {
    showModal = true;
    isEditing = false;
    tag = {
      label: '',
      description: '',
      bg: '#b8c9ff',
      color: '#585da7',
      links: []
    };
  };

  const reset = () => {
    uniqueTagLabel = true;
    showModal = false;
    showConfirmModal = false;
    isEditing = false;
    tag = {
      label: '',
      description: '',
      bg: '#b8c9ff',
      color: '#585da7',
      links: []
    };
  };

  $: updateQueryParams(queryParams);
  $: query.updateOptions({ queryKey: ['tags', queryParams], queryFn });
  $: tags = $query.data || [];
  $: $tagsSettingsStore.showFilters = showFilters;
</script>

<div class="page tags with-footer">
  <Loader backdrop show={$query.isLoading || $query.isFetching || isUpdating} />
  <div class="page-header">
    <div class="row">
      <div class="col-md-12">
        <h1>{$format('label.MANAGE_TAGS')}</h1>
        <Button on:click={create}>{$format('label.NEW_TAG')}</Button>
      </div>
    </div>
  </div>
  <div class="page-content" class:filters-shown={showFilters}>
    <div class="top">
      <FilterBar bind:show={showFilters}>
        <SearchFilter bind:search={queryParams.search} />
      </FilterBar>
    </div>
    <Grid
      className="tags-grid"
      bind:columns
      bind:rows={tags}
      bind:sortKey={queryParams.field}
      bind:sortOrder={queryParams.order}
      onSort={onColumnSort}
    />
  </div>

  <div class="page-footer">
    {#if tags?.length}
      <Pagination
        bind:currentPage={queryParams.page}
        bind:perPage={queryParams.limit}
        bind:totalRecords
        bind:totalPages
        {onChangeRecords}
        {onChangePage}
      />
    {/if}
  </div>
</div>

{#if showModal}
  <EditTagModal
    bind:show={showModal}
    bind:tag
    bind:editing={isEditing}
    bind:uniqueTagLabel
    onSave={queryFn}
    onClose={reset}
  />
{/if}

{#if showConfirmModal}
  <DeleteTagModal bind:show={showConfirmModal} bind:tag bind:isUpdating onSave={queryFn} onClose={reset} />
{/if}

<style lang="scss" src="./tags.scss"></style>
