export const COLOR_PALETTE = [
  'rgba(0, 100, 200, 0.99)',
  'rgba(255, 99, 132, 0.99)',
  'rgba(45, 182, 125, 0.99)',
  'rgba(236,159, 64, 0.99)',
  'rgba(54, 162, 235, 0.99)',
  'rgba(224, 29, 90, 0.99)',
  'rgba(153, 102, 255, 0.99)',
  'rgba(255, 206, 86, 0.99)',
  'rgba(75, 192, 192, 0.99)',
  'rgba(234, 77, 100, 0.99)',
  'rgba(84, 170, 255, 0.99)',
  'rgba(201, 203, 207, 0.99)',
  'rgba(220, 220, 57, 0.99)',
  'rgba(245, 105, 180, 0.99)',
  'rgba(84, 170, 255, 0.99)',
  'rgba(102, 255, 153, 0.99)',
  'rgba(255, 133, 27, 0.99)'
];
