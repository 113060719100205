<script>
  import { format } from 'svelte-i18n';
  import { Button } from 'sveltestrap';

  export let show = true;
</script>

{#if show}
  <div class="filters">
    <slot />
  </div>
{/if}

<Button size="sm" class="filters-toggle {!show ? 'no-filters' : ''}" on:click={() => (show = !show)}>
  {#if show}
    {$format('label.HIDE_FILTERS')}
  {:else}
    {$format('label.SHOW_FILTERS')}
  {/if}
</Button>

<style lang="scss" src="./filter-bar.scss"></style>
