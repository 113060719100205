<script>
  import { Menu } from '@client/components/menu';

  export let row = {};
  export let currentUser = {};
  export let edit = () => {};
  export let remove = () => {};

  let show = false;

  const getMenuItems = (user) => {
    return [
      {
        onClick: (e, user) => edit(user),
        type: 'icon',
        iconClass: 'links-icon-edit-2'
      },
      {
        onClick: (e, user) => remove(user),
        type: 'icon',
        iconClass: 'links-icon-trash'
      }
    ];
  };
</script>

{#if currentUser.role === 'admin'}
  <Menu classNames="grid-actions-menu" bind:show source={row} items={getMenuItems(row)} horizontal>
    <button class="links-icon-more-vertical action-icon" class:active={show} on:click={() => (show = !show)} />
  </Menu>
{/if}

<style lang="scss">
  :global(.grid-actions-menu) {
    right: 20px;
    top: -12px !important;
  }

  .action-icon {
    border: 1px solid transparent;
    border-radius: var(--border-radius-base);
    color: var(--color-text);
    cursor: pointer;
    padding: var(--padding-small) var(--padding-xsmall);

    &.active,
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-gray-200);
    }
  }
</style>
