import { dropdown, input, panel, pill, sidebar, tooltip } from './components';

export const base = {
  app: {
    height: '100vh'
  },
  color: {
    actions: '#d52b1e',
    black: '#21333d',
    body: '#fff',
    text: '#272e3a',
    mint: '#1fd1b6',
    white: '#fff',
    navy: '#006cb7',
    active: '#4653f6',
    indigo: '#1f3d4e',
    gray: {
      0: '#fafafa',
      100: '#f5f5f5',
      200: '#eceff1',
      300: '#dae0e1',
      400: '#d1d3d6',
      500: '#b9bdc1',
      600: '#8b9198',
      700: '#5d656f',
      800: '#454f5a',
      900: '#232a32',
      1000: '#172331'
    },
    green: {
      0: '#caf0d3',
      100: '#81f5c4',
      200: '#65e6b0',
      300: '#4ad69b',
      400: '#35cd8d',
      500: '#1fb777'
    },
    yellow: {
      100: '#fff8ed',
      200: '#ffe9bf',
      300: '#ffd37f',
      400: '#ffbc3d'
    },
    blue: {
      0: '#e7f7fc',
      100: '#99cfff',
      200: '#80c3ff',
      300: '#4dabff',
      400: '#0066ff',
      500: '#0044eb'
    },
    orange: {
      0: '#fef6e3',
      100: '#ffeba1',
      200: '#f1d674',
      300: '#e7ca65',
      400: '#d6b74a',
      500: '#d8ac10'
    },
    red: {
      0: '#ffe7e3',
      100: '#ffc0b7',
      200: '#ff9a8c',
      300: '#ff6666',
      400: '#ff3c3c',
      500: '#d52b1e'
    }
  },
  link: {
    color: {
      _: '#0848a7',
      active: '#043f96',
      hover: '#043f96'
    },
    decoration: {
      _: 'none',
      active: 'none',
      decoration: 'underline'
    }
  },
  brand: {
    primary: 'var(--color-blue-400)',
    success: 'var(--color-green-400)',
    info: 'var(--color-yellow-400)',
    warning: 'var(--color-orange-400)',
    danger: 'var(--color-red-400)',
    blue: '#4653f6',
    capri: '#1bbbff',
    carbon: '#21333d',
    opal: '#ec6454',
    sandy: '#f3a86d',
    green: '#5ec1b2'
  },
  font: {
    family: {
      base: 'var(--font-family-sansserif)',
      monospace: "'ui-monospace', 'SFMono-Regular', Menlo, Monaco, Consolas, 'Courier New', monospace",
      sansserif: "'Rubik', sans-serif",
      serif: "'ui-serif', Georgia, 'Times New Roman', Times, serif"
    },
    size: {
      jumbo: '2rem',
      xxxlarge: '1.75rem',
      xxlarge: '1.5rem',
      xlarge: '1.375rem',
      large: '1.125rem',
      base: '14px',
      medium: '0.875rem',
      small: '0.75rem',
      xsmall: '0.625rem',
      xxsmall: '0.5rem',
      xxxsmall: '0.375rem'
    },
    weight: {
      thin: 100,
      light: 300,
      base: 400,
      medium: 500,
      bold: 700,
      black: 900
    }
  },
  line: {
    height: {
      small: 1,
      base: 1.25,
      large: 1.5,
      computed: 'calc(var(--font-size-base) * var(--line-height-base))'
    }
  },
  heading: {
    color: 'inherit',
    weight: 'var(--font-weight-base)',
    line: {
      height: 'var(--line-height-base)'
    },
    font: {
      family: 'inherit',
      size: {
        title: 'var(--font-size-jumbo)',
        h1: 'var(--font-size-xxxlarge)',
        h2: 'var(--font-size-xxlarge)',
        h3: 'var(--font-size-xlarge)',
        h4: 'var(--font-size-large)',
        h5: 'var(--font-size-base)',
        h6: 'var(--font-size-medium)',
        section: 'var(--font-size-medium)'
      },
      weight: {
        title: 'var(--font-weight-medium)',
        h1: 'var(--font-weight-medium)',
        h2: 'var(--font-weight-medium)',
        h3: 'var(--font-weight-medium)',
        h4: 'var(--font-weight-medium)',
        h5: 'var(--font-weight-medium)',
        h6: 'var(--font-weight-medium)'
      }
    }
  },
  border: {
    color: 'var(--color-gray-200)',
    radius: {
      small: '.125rem',
      base: '.25rem',
      large: '.5rem',
      xlarge: '.75rem',
      xxlarge: '1rem',
      xxxlarge: '1.125rem'
    }
  },
  spacing: '8px',
  margin: {
    xsmall: 'calc(var(--spacing) / 4)',
    small: 'calc(var(--spacing) / 2)',
    base: 'var(--spacing)',
    large: 'calc(var(--spacing) * 2)',
    xlarge: 'calc(var(--spacing) * 3)',
    xxlarge: 'calc(var(--spacing) * 4)',
    xxxlarge: 'calc(var(--spacing) * 5)',
    jumbo: 'calc(var(--spacing) * 6)'
  },
  padding: {
    xsmall: 'calc(var(--spacing) / 4)',
    small: 'calc(var(--spacing) / 2)',
    base: 'var(--spacing)',
    large: 'calc(var(--spacing) * 2)',
    xlarge: 'calc(var(--spacing) * 3)',
    xxlarge: 'calc(var(--spacing) * 4)',
    xxxlarge: 'calc(var(--spacing) * 5)',
    jumbo: 'calc(var(--spacing) * 6)'
  },
  box: {
    shadow: {
      small: '0 1px 1px rgba(0,0,0, .1)',
      base: '0 1px 3px rgba(0,0,0, .1)',
      large: '0 1px 5px rgba(0,0,0, .1)'
    }
  },
  layout: {
    content: {
      border: {
        left: '1px solid var(--border-color)'
      },
      boxShadow: '0'
    }
  },
  dropdown,
  input,
  panel,
  pill,
  sidebar,
  tooltip
};
