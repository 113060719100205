<script>
  import { initialsFromEmail, initialsFromName } from '@client/utils/users';

  /**
   * Object containing user details.
   * @type {Object}
   * @public
   */
  export let details = {};

  /**
   * Size of the element.
   * @type {string}
   * @default 'medium'
   * @public
   */
  export let size = 'medium';

  /**
   * Color of the element.
   * @type {string}
   * @default 'var(--color-gray-200)'
   * @public
   */
  export let color = 'var(--color-gray-200)';

  /**
   * Cachebust the image URL.
   * @type {boolean}
   * @default true
   * @public
   */
  export let cachebust = true;

  const renderUserInitials = () => {
    imageUrl = null;
  };

  const getInitials = () => {
    if (details.name) {
      return initialsFromName(details.name);
    }

    return initialsFromEmail(details.email);
  };

  const checkImage = (url, callback) => {
    const img = new Image();

    img.onload = () => {
      if (img.width === 1 && img.height === 1) {
        if (callback) {
          callback(url);
        } else {
          renderUserInitials();
        }
      } else {
        imageUrl = url;
      }
    };

    img.onerror = () => renderUserInitials();
    img.src = url;

    return url;
  };

  let imageUrl = details?.imageUrl || null;

  if (!details?.imageUrl?.length) {
    renderUserInitials();
  }

  $: imageUrl = details?.imageUrl || null;
</script>

{#key imageUrl}
  <div class="porthole {size}" class:initials={!imageUrl}>
    {#if imageUrl?.length}
      <img
        src="{imageUrl}{cachebust ? `?ts=${Date.now()}` : ''}"
        on:error={renderUserInitials}
        alt={details.name}
        title={details.name}
        referrerpolicy="no-referrer"
      />
    {:else}
      <div class="initials" style="background-color: {color};">{getInitials()}</div>
    {/if}
  </div>
{/key}

<style lang="scss" src="./porthole.scss"></style>
