import { APITransport } from '@client/transports/api';

export const UrlsService = {
  getAll(query = {}) {
    return APITransport.get({
      path: '/v1/urls',
      query
    });
  },

  get(shortHash) {
    return APITransport.get({
      path: `/v1/urls/${shortHash}`
    });
  },

  create({ createdBy, diff, editors, expiry, originalLink, originalUrl, tags, title, updatedBy }) {
    return APITransport.post({
      path: '/v1/urls',
      body: {
        createdBy,
        diff,
        editors,
        expiry,
        originalUrl,
        originalLink,
        tags,
        title,
        updatedBy
      }
    });
  },

  delete({ domainHash, shortHash, createdBy, tags }) {
    return APITransport.delete({
      path: `/v1/urls/${shortHash}`,
      body: { domainHash, user: createdBy.user, tags }
    });
  },

  update({
    createdBy,
    diff,
    expiry,
    domainHash,
    editors,
    originalLink,
    originalUrl,
    shortHash,
    tags,
    title,
    updatedBy
  }) {
    return APITransport.put({
      path: `/v1/urls/${shortHash}`,
      body: {
        createdBy,
        diff,
        domainHash,
        editors,
        expiry,
        originalUrl,
        originalLink,
        shortHash,
        tags,
        title,
        updatedBy
      }
    });
  }
};
