import { addDays, format, formatDistance, parseISO } from 'date-fns';

export const formatTimestamp = (timestamp, formatOutput) => {
  if (!timestamp) {
    return '-';
  }

  const date = new Date(parseISO(timestamp));

  return format(date, formatOutput);
};

export const relativeTimestamp = (timestamp) => {
  return formatDistance(new Date(parseISO(timestamp)), new Date(), { addSuffix: true });
};

export const padNumber = (date) => {
  return date.toString().padStart(2, '0');
};

export const generateDatesInRange = function (start, end) {
  const dates = [];

  for (let currentDate = new Date(start); currentDate < new Date(end); currentDate.setDate(currentDate.getDate() + 1)) {
    // const date = addDays(new Date(currentDate), 1);

    dates.push(
      `${currentDate.getFullYear()}-${padNumber(currentDate.getMonth() + 1)}-${padNumber(currentDate.getDate())}`
    );
  }

  return dates;
};

const formatDistanceLocale = {
  lessThanXSeconds: '{count}s',
  xSeconds: '{count}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{count}m',
  xMinutes: '{count}m',
  aboutXHours: '{count}h',
  xHours: '{count}h',
  xDays: '{count}d',
  aboutXWeeks: '{count}w',
  xWeeks: '{count}w',
  aboutXMonths: '{count}m',
  xMonths: '{count}m',
  aboutXYears: '{count}y',
  xYears: '{count}y',
  overXYears: '{count}y',
  almostXYears: '{count}y'
};

export const formatDistanceRelative = (token, count, options = {}) => {
  const result = formatDistanceLocale[token].replace('{count}', count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
};
