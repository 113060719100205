<script>
  import { format } from 'svelte-i18n';
  import calendarize from 'calendarize';

  import { getDateFromToday, MILLISECONDS_IN_DAY } from '@common/utils/dates';

  import { clickOutside } from '@client/actions/click-outside';

  /**
   * Start date for the date range.
   * @type {Date}
   * @default null
   */
  export let startDate = null;

  /**
   * End date for the date range.
   * @type {Date}
   * @default null
   */
  export let endDate = null;

  /**
   * Start time for the date range in 'HH:mm' format.
   * @type {string}
   * @default '00:00'
   */
  export let startDateTime = '00:00';

  /**
   * End time for the date range in 'HH:mm' format.
   * @type {string}
   * @default '00:00'
   */
  export let endDateTime = '00:00';

  /**
   * Current date.
   * @type {Date}
   * @default Current system date
   */
  export let today = new Date();

  /**
   * Default year for the date range.
   * @type {number}
   * @default Current year
   */
  export let defaultYear = today.getFullYear();

  /**
   * Default month for the date range.
   * @type {number}
   * @default Current month
   */
  export let defaultMonth = today.getMonth();

  /**
   * Index of the starting day of the week (0 for Sunday, 1 for Monday, and so on).
   * @type {number}
   * @default 0
   */
  export let startOfWeek = 0;

  /**
   * Indicates whether the date picker should have multiple panes.
   * @type {boolean}
   * @default false
   */
  export let isMultipane = false;

  /**
   * Indicates whether the date picker should be in range mode.
   * @type {boolean}
   * @default true
   */
  export let isRange = true;

  /**
   * Indicates whether the date picker is open.
   * @type {boolean}
   * @default false
   */
  export let isOpen = false;

  /**
   * Alignment of the date picker ('left', 'center', or 'right').
   * @type {string}
   * @default 'left'
   */
  export let align = 'left';

  /**
   * Theme to be applied to the date picker.
   * @type {string}
   * @default ''
   */
  export let theme = '';

  /**
   * Array of dates that should be disabled in the date picker.
   * @type {string[]}
   * @default []
   */
  export let disabledDates = [];

  /**
   * Callback function triggered on clicking a day.
   * @type {function}
   * @default () => {}
   */
  export let onDayClick = () => {};

  /**
   * Callback function triggered when a new start and end date are set.
   * @type {function}
   * @default () => {}
   */
  export let onDateChange = () => {};

  /**
   * Indicates whether the date picker should always be visible.
   * @type {boolean}
   * @default false
   */
  export let alwaysShow = false;

  /**
   * Indicates whether year controls should be displayed.
   * @type {boolean}
   * @default true
   */
  export let showYearControls = true;

  /**
   * Indicates whether preset date ranges should be displayed.
   * @type {boolean}
   * @default false
   */
  export let showPresets = false;

  /**
   * Indicates whether preset date ranges should only be displayed
   * @type {boolean}
   * @default false
   */
  export let showPresetsOnly = false;

  /**
   * Indicates whether a time picker should be displayed.
   * @type {boolean}
   * @default false
   */
  export let showTimePicker = false;

  /**
   * Indicates whether future dates should be enabled.
   * @type {boolean}
   * @default false
   */
  export let enableFutureDates = false;

  /**
   * Indicates whether past dates should be enabled.
   * @type {boolean}
   * @default true
   */
  export let enablePastDates = true;

  /**
   * Array of preset date range labels.
   * @type {string[]}
   */
  export let presets = [
    $format('label.TODAY'),
    $format('label.LAST_X_DAYS', { values: { total: 7 } }),
    $format('label.LAST_X_DAYS', { values: { total: 30 } }),
    $format('label.LAST_X_DAYS', { values: { total: 60 } }),
    $format('label.LAST_X_DAYS', { values: { total: 90 } }),
    $format('label.LAST_YEAR')
  ];

  /**
   * Array of day labels for the date picker header.
   * @type {string[]}
   */
  export let labels = [
    $format('label.DAYS_SHORTENED.SUN'),
    $format('label.DAYS_SHORTENED.MON'),
    $format('label.DAYS_SHORTENED.TUE'),
    $format('label.DAYS_SHORTENED.WED'),
    $format('label.DAYS_SHORTENED.THU'),
    $format('label.DAYS_SHORTENED.FRI'),
    $format('label.DAYS_SHORTENED.SAT')
  ];

  /**
   * Array of month labels for the month dropdown.
   * @type {string[]}
   */
  export let months = [
    $format('label.MONTH_NAMES.JANUARY'),
    $format('label.MONTH_NAMES.FEBRUARY'),
    $format('label.MONTH_NAMES.MARCH'),
    $format('label.MONTH_NAMES.APRIL'),
    $format('label.MONTH_NAMES.MAY'),
    $format('label.MONTH_NAMES.JUNE'),
    $format('label.MONTH_NAMES.JULY'),
    $format('label.MONTH_NAMES.AUGUST'),
    $format('label.MONTH_NAMES.SEPTEMBER'),
    $format('label.MONTH_NAMES.OCTOBER'),
    $format('label.MONTH_NAMES.NOVEMBER'),
    $format('label.MONTH_NAMES.DECEMBER')
  ];

  /**
   * Array of preset date ranges with labels, total days, start dates, and end dates.
   * @type {Object[]}
   */
  export let presetRanges = [
    {
      label: presets[0],
      total: 0,
      start: getDateFromToday(0),
      end: getDateFromToday(0)
    },
    {
      label: presets[1],
      total: 6,
      start: getDateFromToday(6),
      end: getDateFromToday(0)
    },
    {
      label: presets[2],
      total: 29,
      start: getDateFromToday(29),
      end: getDateFromToday(0)
    },
    {
      label: presets[3],
      total: 59,
      start: getDateFromToday(59),
      end: getDateFromToday(0)
    },
    {
      label: presets[4],
      total: 89,
      start: getDateFromToday(89),
      end: getDateFromToday(0)
    },
    {
      label: presets[4],
      total: 364,
      start: getDateFromToday(364),
      end: getDateFromToday(0)
    }
  ];

  let initialize = false;
  let tempEndDate;
  let prevStartDate;
  let prevEndDate;

  const createTimestamp = (year, month, day) => new Date(year, month, day).getTime();
  const getTimestamp = (date) => new Date(date).getTime();

  const normalizeTimestamp = (o) => {
    const date = new Date(o);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  const onClickOutside = () => {
    if (alwaysShow) {
      return;
    }

    if (prevStartDate && prevEndDate && startDate && !endDate) {
      startDate = prevStartDate;
      endDate = prevEndDate;
      prevStartDate = null;
      prevEndDate = null;
    }

    isOpen = false;
  };

  let startDateYear = Number(defaultYear);
  let startDateMonth = Number(defaultMonth);

  const updateCalendars = () => {
    startDateCalendar = startDateCalendar;
    endDateCalendar = endDateCalendar;
  };

  const toPrev = () => {
    [startDateCalendar, next] = [prev, startDateCalendar];

    if (--startDateMonth < 0) {
      startDateMonth = 11;
      startDateYear--;
    }
  };

  const toPrevYear = () => {
    startDateYear--;
  };

  const toNext = () => {
    [prev, startDateCalendar] = [startDateCalendar, next];

    if (++startDateMonth > 11) {
      startDateMonth = 0;
      startDateYear++;
    }
  };

  const toNextYear = () => {
    startDateYear++;
  };

  const isToday = (day, month, year) => {
    return today && todayMonth === month && todayDay === day && todayYear === year;
  };

  const handleSingleDate = (timestamp) => {
    startDate = updateTime('start', timestamp);

    if (!alwaysShow) {
      isOpen = false;
    }
  };

  const handleDateRange = (timestamp) => {
    if (startDate === null || (startDate !== null && endDate !== null)) {
      startDate = updateTime('start', timestamp);
      endDate = null;
    } else {
      endDate = updateTime('end', timestamp);
      if (startDate > endDate) {
        [startDate, endDate] = [endDate, startDate];
      }

      if (!alwaysShow) {
        isOpen = false;
      }
    }
  };

  const getDatesInRange = (startDate, endDate, disabled) => {
    const dateRangeStart = new Date(startDate);
    const dateRangeEnd = new Date(endDate);
    const datesInRange = [];

    for (; dateRangeStart <= dateRangeEnd; dateRangeStart.setDate(dateRangeStart.getDate() + 1)) {
      const formattedDate = `${
        dateRangeStart.getMonth() + 1
      }/${dateRangeStart.getDate()}/${dateRangeStart.getFullYear()}`;
      if (!disabled.includes(formattedDate)) {
        datesInRange.push(formattedDate);
      }
    }

    return datesInRange;
  };

  const onClick = function (e, day, month, year) {
    const classes = e.target?.closest('.date').className;

    if (classes.includes('future') || classes.includes('past') || classes.includes('disabled')) {
      e.preventDefault();
      return false;
    }

    const timestamp = createTimestamp(year, month, day);

    if (isRange) {
      handleDateRange(timestamp);
    } else {
      handleSingleDate(timestamp);
    }

    const event = {
      startDate,
      startDateTime,
      ...(isRange && {
        endDate,
        endDateTime,
        rangeDates: getDatesInRange(startDate, endDate, disabled)
      })
    };

    onDayClick(event);

    if (isRange && startDate && endDate) {
      onDateChange(event);
    }
  };

  const isDateInRange = (start, end, selected) => {
    const startCompare = normalizeTimestamp(start);
    const endCompare = normalizeTimestamp(end);
    const selectedCompare = normalizeTimestamp(selected);

    return selectedCompare >= startCompare && selectedCompare <= endCompare;
  };

  const inRange = (day, month, year) => {
    const selectedDateTimestamp = createTimestamp(year, month, day);

    if (normalizeTimestamp(startDate) === selectedDateTimestamp) {
      return true;
    }

    return isRange ? isDateInRange(startDate, endDate, selectedDateTimestamp) : startDate === selectedDateTimestamp;
  };

  const isFirstInRange = (day, month, year) => {
    const currentTimestamp = createTimestamp(year, month, day);
    const startCompare = normalizeTimestamp(startDate);
    const tempEndCompare = normalizeTimestamp(tempEndDate);
    const currentCompare = normalizeTimestamp(currentTimestamp);

    if (isRange && !endDate && tempEndDate) {
      return startCompare === currentCompare;
    }

    return isRange ? startCompare === currentCompare : tempEndCompare === currentCompare;
  };

  const isLastInRange = (day, month, year) => {
    const currentTimestamp = createTimestamp(year, month, day);
    const endCompare = normalizeTimestamp(endDate);
    const startCompare = normalizeTimestamp(startDate);
    const currentCompare = normalizeTimestamp(currentTimestamp);
    const tempEndCompare = normalizeTimestamp(tempEndDate);

    if (isRange && startDate && !endDate && tempEndDate) {
      return tempEndCompare === startCompare;
    }

    return isRange ? endCompare === currentCompare : startCompare === currentCompare;
  };

  const isDisabled = (day, month, year) => {
    const selectedDateTimestamp = createTimestamp(year, month, day);
    return disabled.map((d) => new Date(d).getTime()).includes(selectedDateTimestamp);
  };

  const isFutureDate = (day, month, year) => {
    if (enableFutureDates) {
      return false;
    }

    const selectedDateTimestamp = createTimestamp(year, month, day);
    const todayCompare = normalizeTimestamp(new Date());
    const selectedCompare = normalizeTimestamp(selectedDateTimestamp);

    return todayCompare < selectedCompare;
  };

  const isPastDate = (day, month, year) => {
    if (enablePastDates) {
      return false;
    }

    const selectedDateTimestamp = createTimestamp(year, month, day);
    const todayCompare = normalizeTimestamp(new Date());
    const selectedCompare = normalizeTimestamp(selectedDateTimestamp);

    return todayCompare > selectedCompare;
  };

  const isFirstDayOfMonth = (day) => {
    return day === 1;
  };

  const isLastDayOfMonth = (day, calendar) => {
    return day === Math.max(...calendar.flat(10));
  };

  const onMouseEnter = function (e, day, month, year) {
    if (startDate && endDate) {
      tempEndDate = null;
      return;
    }

    const { className: classes } = e.target || {};

    if (classes.includes('future') || classes.includes('past') || classes.includes('disabled')) {
      return;
    }

    tempEndDate = createTimestamp(year, month, day);
  };

  const onMouseLeave = () => {
    if (startDate && endDate) {
      tempEndDate = null;
      return;
    }

    tempEndDate = normalizeTimestamp(startDate);
  };

  const inRangeHover = (day, month, year) => {
    if (!isRange || endDate || !startDate || !tempEndDate) {
      return;
    }

    const dateString = createTimestamp(year, month, day);
    const startCompare = normalizeTimestamp(startDate);
    const tempEndCompare = normalizeTimestamp(tempEndDate);
    const selectedCompare = normalizeTimestamp(dateString);

    const minDate = startCompare < tempEndCompare ? startCompare : tempEndCompare;
    const maxDate = startCompare > tempEndCompare ? startCompare : tempEndCompare;

    return selectedCompare >= minDate && selectedCompare <= maxDate;
  };

  const onPresetClick = ({ start, end }) => {
    startDate = start;
    endDate = end;

    if (isRange && startDate && endDate) {
      onDateChange({
        startDate,
        startDateTime,
        endDate,
        endDateTime,
        rangeDates: getDatesInRange(startDate, endDate, disabled)
      });
    }

    if (!alwaysShow) {
      isOpen = false;
    }
  };

  const updateTime = (which, timestamp) => {
    const date = new Date(timestamp);

    if (!showTimePicker) {
      return date.getTime();
    }

    const [hours, minutes] = (which === 'start' ? startDateTime : endDateTime).split(':');

    date.setHours(hours);
    date.setMinutes(minutes);

    return date.getTime();
  };

  const getHoursAndMinutes = (date) => {
    date = new Date(date);

    if (!date) {
      return '00:00';
    }

    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
  };

  $: startDate = startDate ? getTimestamp(startDate) : null;
  $: endDate = endDate ? getTimestamp(endDate) : null;
  $: if (startDate || endDate) {
    updateCalendars();
  }
  $: todayMonth = today && today.getMonth();
  $: todayDay = today && today.getDate();
  $: todayYear = today && today.getFullYear();
  $: prev = calendarize(new Date(startDateYear, startDateMonth - 1), startOfWeek);
  $: startDateCalendar = calendarize(new Date(startDateYear, startDateMonth), startOfWeek);
  $: next = calendarize(new Date(startDateYear, startDateMonth + 1), startOfWeek);
  $: endDateMonth = startDateMonth === 11 ? 0 : startDateMonth + 1;
  $: endDateYear = endDateMonth === 0 ? startDateYear + 1 : startDateYear;
  $: endDateCalendar = calendarize(new Date(endDateYear, endDateMonth), startOfWeek);
  $: !isRange && (endDate = null);
  $: presets &&
    (presetRanges = presetRanges.map((item, index) => {
      item.label = presets[index];
      return item;
    }));
  $: theme !== null && document.documentElement.setAttribute('data-picker-theme', theme);
  $: disabled = disabledDates.reduce((acc, date) => {
    let newDates = [];
    if (date instanceof Date) {
      newDates = [date.getTime()];
    } else if (typeof date === 'string' && date.includes(':')) {
      const [rangeStart, rangeEnd] = date.split(':');
      let dateRangeStart = new Date(rangeStart).getTime();
      let dateRangeEnd = new Date(rangeEnd).getTime();

      for (; dateRangeStart <= dateRangeEnd; dateRangeStart += MILLISECONDS_IN_DAY) {
        newDates = [...newDates, dateRangeStart];
      }
    } else {
      newDates = [new Date(date).getTime()];
    }

    return [...acc, ...newDates];
  }, []);

  $: if (!startDate && !endDate) {
    startDateYear = Number(defaultYear);
    startDateMonth = Number(defaultMonth);
  }

  $: if (isRange !== null || (startDate && tempEndDate !== null) || !isOpen) {
    updateCalendars();
  }

  $: if (isOpen) {
    if ((!isRange && startDate) || (isRange && startDate && endDate)) {
      const date = new Date(startDate);
      startDateYear = date.getFullYear();
      startDateMonth = date.getMonth();
    }
  }

  $: if (startDate && showTimePicker && !initialize) {
    startDateTime = getHoursAndMinutes(startDate);
    endDateTime = getHoursAndMinutes(endDate);
    initialize = true;
  }
</script>

<div class="datepicker" data-picker-theme={theme} use:clickOutside={{ onClickOutside }}>
  <slot />
  <div
    class="calendars-container"
    class:right={align === 'right'}
    class:range={isRange && isMultipane}
    class:presets={isRange && showPresets}
    class:show={isOpen}
  >
    {#if isRange && showPresets}
      <div class="calendar-presets" class:presets-only={showPresetsOnly}>
        {#each presetRanges as option}
          <button
            class:active={normalizeTimestamp(startDate) === normalizeTimestamp(option.start) &&
              normalizeTimestamp(endDate) === normalizeTimestamp(option.end)}
            on:click={() => onPresetClick({ ...option })}
          >
            {option.label}
          </button>
        {/each}
      </div>
    {/if}
    <div class="calendar" class:presets-only={isRange && showPresetsOnly}>
      <header class:timepicker={showTimePicker}>
        <button on:click={toPrev}>
          <i class="links-icon-arrow-left" aria-label="Previous month" />
        </button>
        <span>
          <div>{months[startDateMonth]} {startDateYear}</div>

          {#if showYearControls}
            <div class="years">
              <button on:click={toNextYear}>
                <i class="links-icon-chevron-up" aria-label="Next year" />
              </button>
              <button on:click={toPrevYear}>
                <i class="links-icon-chevron-down" aria-label="Previous year" />
              </button>
            </div>
          {/if}
        </span>
        <button on:click={toNext} class:hide={!(!isRange || (isRange && !isMultipane))}>
          <i class="links-icon-arrow-right" aria-label="Next year" />
        </button>
      </header>

      {#if showTimePicker}
        <div class="timepicker" class:show={isRange && !isMultipane}>
          <input type="time" bind:value={startDateTime} on:input={() => (startDate = updateTime('start', startDate))} />

          {#if isRange}
            <input
              type="time"
              class="end-time"
              bind:value={endDateTime}
              on:input={() => (endDate = updateTime('end', endDate))}
            />
          {/if}
        </div>
      {/if}

      <div class="month">
        {#each labels as text, labelIndex (text)}
          <span class="label">{labels[(labelIndex + startOfWeek) % 7]}</span>
        {/each}

        {#each { length: 6 } as week, weekIndex (weekIndex)}
          {#if startDateCalendar[weekIndex]}
            {#each { length: 7 } as d, dayIndex (dayIndex)}
              {#if startDateCalendar[weekIndex][dayIndex] !== 0}
                <button
                  class="date"
                  class:today={isToday(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:start={isFirstInRange(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:end={isLastInRange(
                    startDateCalendar[weekIndex][dayIndex],
                    startDateMonth,
                    startDateYear,
                    startDateCalendar
                  )}
                  class:range={inRange(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:rangehover={inRangeHover(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:past={isPastDate(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:future={isFutureDate(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:first={isFirstDayOfMonth(startDateCalendar[weekIndex][dayIndex])}
                  class:last={isLastDayOfMonth(startDateCalendar[weekIndex][dayIndex], startDateCalendar)}
                  class:disabled={isDisabled(startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  on:mouseenter={(e) =>
                    onMouseEnter(e, startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  on:mouseleave={(e) =>
                    onMouseLeave(e, startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  on:click={(e) => onClick(e, startDateCalendar[weekIndex][dayIndex], startDateMonth, startDateYear)}
                  class:norange={isRange && tempEndDate === startDate}
                >
                  <span>{startDateCalendar[weekIndex][dayIndex]}</span>
                </button>
              {:else}
                <div class="date other">&nbsp;</div>
              {/if}
            {/each}
          {/if}
        {/each}
      </div>
    </div>

    {#if isRange && isMultipane}
      <div class="calendar" class:presets-only={showPresetsOnly}>
        <header class:timepicker={showTimePicker}>
          <button on:click={toPrev} class:hide={!(!isRange || (isRange && !isMultipane))}>
            <i class="links-icon-arrow-left" aria-label="Previous month" />
          </button>
          <span>
            <div>{months[endDateMonth]} {endDateYear}</div>

            {#if showYearControls}
              <div class="years">
                <button on:click={toNextYear}>
                  <i class="links-icon-chevron-up" aria-label="Next year" />
                </button>
                <button on:click={toPrevYear}>
                  <i class="links-icon-chevron-down" aria-label="Previous year" />
                </button>
              </div>
            {/if}
          </span>
          <button on:click={toNext}>
            <i class="links-icon-arrow-right" aria-label="Next year" />
          </button>
        </header>

        {#if showTimePicker}
          <div class="timepicker">
            <input type="time" bind:value={endDateTime} on:input={() => (endDate = updateTime('end', endDate))} />
          </div>
        {/if}

        <div class="month">
          {#each labels as text, labelIndex (text)}
            <span class="label">{labels[(labelIndex + startOfWeek) % 7]}</span>
          {/each}

          {#each { length: 6 } as week, weekIndex (weekIndex)}
            {#if endDateCalendar[weekIndex]}
              {#each { length: 7 } as d, dayIndex (dayIndex)}
                {#if endDateCalendar[weekIndex][dayIndex] !== 0}
                  <button
                    class="date"
                    class:today={isToday(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:range={inRange(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:rangehover={inRangeHover(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:start={isFirstInRange(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:end={isLastInRange(
                      endDateCalendar[weekIndex][dayIndex],
                      endDateMonth,
                      endDateYear,
                      endDateCalendar
                    )}
                    class:past={isPastDate(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:future={isFutureDate(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:first={isFirstDayOfMonth(endDateCalendar[weekIndex][dayIndex])}
                    class:last={isLastDayOfMonth(endDateCalendar[weekIndex][dayIndex], endDateCalendar)}
                    class:disabled={isDisabled(endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    on:mouseenter={(e) =>
                      onMouseEnter(e, endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    on:mouseleave={(e) =>
                      onMouseLeave(e, endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    on:click={(e) => onClick(e, endDateCalendar[weekIndex][dayIndex], endDateMonth, endDateYear)}
                    class:norange={isRange && tempEndDate === startDate}
                  >
                    <span>{endDateCalendar[weekIndex][dayIndex]}</span>
                  </button>
                {:else}
                  <div class="date other">&nbsp;</div>
                {/if}
              {/each}
            {/if}
          {/each}
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss" src="./datepicker.scss"></style>
