<script>
  import CodeMirror from 'svelte-codemirror-editor';
  import { autocompletion } from '@codemirror/autocomplete';
  import { bracketMatching } from '@codemirror/language';
  import { search } from '@codemirror/search';
  import { EditorView, gutter } from '@codemirror/view';
  import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
  import { liquid } from '@codemirror/lang-liquid';
  import { ayuLight } from 'thememirror';

  import { liquidHighlighting, liquidStyles } from './config/decorators';

  /**
   * Represents the content of the code.
   * @type {string}
   * @default ''
   */
  export let value = '';

  /**
   * Determines if longer lines are wrapped
   * @type {boolean}
   * @default false
   */
  export let lineWrapping = false;

  /**
   * Determines if the tab key is used for indentation or navigation.
   * @type {boolean}
   * @default true
   */
  export let useTab = true;

  /**
   * Determines if the editor should auto-format on blur.
   * @type {Object}
   * @default false
   */

  let extensions = [
    liquidStyles,
    liquidHighlighting,
    bracketMatching(),
    gutter({ class: 'cm-hide-line-numbers' }),
    EditorView.lineWrapping,
    autocompletion(),
    liquid({ base: markdown({ base: markdownLanguage }) }),
    search({ top: true })
  ];
</script>

<CodeMirror bind:value bind:useTab bind:lineWrapping bind:extensions theme={ayuLight} {...$$props} />

<style lang="scss" src="./editor.scss"></style>
