<script>
  import { Loader } from '@client/components/loader';
  import { useQuery } from '@sveltestack/svelte-query/svelte';
  import { format } from 'svelte-i18n';
  import { format as formatDate } from 'date-fns';

  import { FilterBar } from '@client/components/filter-bar';
  import { Grid } from '@client/components/grid';
  import { Pagination } from '@client/components/pagination';
  import { SearchFilter } from '@client/components/filters/search';

  import { UsersService } from '@client/services/users';

  import { appStore } from '@client/stores/app';
  import { userStore } from '@client/stores/user';
  import { usersSettingsStore } from '@client/stores/page-settings';

  import { QueryParams } from '@client/utils/query-params';

  import { ActionsCell, UserCell } from './cell-formatters';

  import { EditModal, DeleteModal } from './modal';

  let users = [];
  let totalRecords = 0;
  let totalPages = 0;
  let showFilters =
    $appStore.isMobile && $usersSettingsStore.showFilters !== undefined ? $usersSettingsStore.showFilters : true;

  let showModal = false;
  let showConfirmModal = false;
  let isEditing = false;
  let isUpdating = false;

  let user = {
    name: '',
    email: '',
    role: ''
  };

  let roleTypes = {
    admin: $format('label.ADMIN'),
    user: $format('label.USER'),
    viewer: $format('label.VIEWER')
  };

  const getParamValue = (property, defaultValue, formatter) => {
    const value = QueryParams.get(property) || $usersSettingsStore.params[property] || defaultValue;

    if (formatter) {
      return formatter(value);
    }

    return value;
  };

  let queryParams = {
    field: getParamValue('field', 'name'),
    limit: getParamValue('perpage', 25, Number),
    order: getParamValue('order', -1),
    page: getParamValue('page', 0, (page) => Math.max(0, page - 1)),
    search: getParamValue('search', '')
  };

  const onChangePage = (page) => {
    $usersSettingsStore.params = { ...queryParams, page };
  };

  const onChangeRecords = (limit) => {
    $usersSettingsStore.params = { ...queryParams, limit };
  };

  const updateQueryParams = () => {
    queryParams = { ...queryParams };
    $usersSettingsStore.params = queryParams;
  };

  const edit = async (selected) => {
    const response = await UsersService.getAll({ searchField: 'email', search: selected.email });

    if (response?.records?.length) {
      const { records } = response;
      const details = records.shift();

      user = { ...details };
      showModal = true;
      isEditing = true;
    }
  };

  const remove = (selected) => {
    user = { ...selected };
    showConfirmModal = true;
  };

  const reset = () => {
    showModal = false;
    isEditing = false;
    showConfirmModal = false;
    user = {
      name: '',
      email: '',
      role: ''
    };
  };

  let columns = [
    {
      id: 'name',
      sortKey: 'name',
      label: $format('label.TEAM_MEMBER'),
      renderComponent: UserCell
    },
    {
      id: 'email',
      sortKey: 'email',
      label: $format('label.EMAIL'),
      renderValue: ({ email }) => email
    },
    {
      id: 'role',
      sortKey: 'role',
      label: $format('label.ROLE'),
      renderValue: ({ role }) => roleTypes[role]
    },
    {
      id: 'links',
      sortable: false,
      label: $format('label.LINKS'),
      renderValue: ({ links }) => links.length
    },
    {
      id: 'urls',
      sortable: false,
      label: $format('label.URLS'),
      renderValue: ({ urls }) => urls.length
    },
    {
      id: 'updatedAt',
      sortKey: 'updatedAt',
      label: $format('label.LAST_SEEN'),
      renderValue: ({ updatedAt }) => formatDate(new Date(updatedAt), 'M/d/yyyy @ h:mm a')
    },
    {
      id: 'actions',
      label: '',
      renderComponent: {
        component: ActionsCell,
        props: {
          edit,
          remove,
          currentUser: $userStore
        }
      }
    }
  ];

  const onColumnSort = (field, order) => {
    $usersSettingsStore.params = { ...queryParams, field, order };
  };

  const queryFn = async () => {
    const { records, pagination } = await UsersService.getAll(queryParams);

    users = records;
    totalRecords = pagination.total;
    totalPages = pagination.pages;
    users = [...users];

    return users;
  };

  const query = useQuery(['teams', queryParams], queryFn, { keepPreviousData: true });

  $: updateQueryParams(queryParams);
  $: query.updateOptions({ queryKey: ['teams', queryParams], queryFn });
  $: users = $query.data || [];
  $: $usersSettingsStore.showFilters = showFilters;
</script>

<div class="page team with-footer">
  <Loader backdrop show={$query.isLoading || $query.isFetching} />
  <div class="page-header no-action">
    <div class="row">
      <div class="col-md-12">
        <h1>{$format('label.MANAGE_TEAM')}</h1>
      </div>
    </div>
  </div>
  <div class="page-content" class:filters-shown={showFilters}>
    <div class="top">
      <FilterBar bind:show={showFilters}>
        <SearchFilter bind:search={queryParams.search} />
      </FilterBar>
    </div>
    <Grid
      className="team-grid"
      bind:columns
      bind:rows={users}
      bind:sortKey={queryParams.field}
      bind:sortOrder={queryParams.order}
      onSort={onColumnSort}
    />
  </div>

  <div class="page-footer">
    {#if users?.length}
      <Pagination
        bind:currentPage={queryParams.page}
        bind:perPage={queryParams.limit}
        bind:totalRecords
        bind:totalPages
        {onChangeRecords}
        {onChangePage}
      />
    {/if}
  </div>
</div>

{#if showModal}
  <EditModal bind:show={showModal} bind:user bind:editing={isEditing} onSave={queryFn} onClose={reset} />
{/if}

{#if showConfirmModal}
  <DeleteModal bind:show={showConfirmModal} bind:user bind:isUpdating onSave={queryFn} onClose={reset} />
{/if}

<style lang="scss" src="./team.scss"></style>
