import { APITransport } from '@client/transports/api';

export const LinksService = {
  getAll(query = {}) {
    return APITransport.get({
      path: '/v1/links',
      query
    });
  },

  get(link) {
    return APITransport.get({
      path: `/v1/links/${link}`
    });
  },

  create({ createdBy, diff, editors, expiry, link, private: priv, source, tags, timeSaved, title, type, updatedBy }) {
    return APITransport.post({
      path: '/v1/links',
      body: {
        link,
        title,
        source,
        private: priv,
        tags,
        editors,
        type,
        createdBy,
        updatedBy,
        diff,
        expiry,
        timeSaved
      }
    });
  },

  delete({ link, user, tags }) {
    return APITransport.delete({
      path: `/v1/links/${link}`,
      body: { user, tags }
    });
  },

  update({ diff, editors, expiry, link, private: priv, source, tags, timeSaved, title, type, updatedBy }) {
    return APITransport.put({
      path: `/v1/links/${link}`,
      body: {
        link,
        title,
        private: priv,
        source,
        tags,
        editors,
        type,
        updatedBy,
        diff,
        expiry,
        timeSaved
      }
    });
  }
};
