export const input = {
  background: {
    _: 'var(--color-white)',
    disabled: 'var(--color-gray-100)'
  },
  border: {
    _: '1px solid var(--color-gray-300)',
    disabled: '1px solid var(--color-gray-300)',
    focus: {
      _: '1px solid var(--color-blue-400)',
      error: '1px solid var(--color-red-400)',
      success: '1px solid var(--color-green-400)'
    },
    error: '1px solid var(--color-red-400)',
    success: '1px solid var(--color-green-400)',
    radius: 'var(--border-radius-base)'
  },

  box: {
    shadow: {
      _: 'none',
      focus: {
        _: '0 0 0 2px var(--color-blue-400)',
        error: '0 0 0 2px var(--color-red-400)',
        success: '0 0 0 2px var(--color-green-400)'
      }
    }
  },

  color: {
    _: 'var(--color-text)',
    disabled: 'var(--color-gray-400)'
  },
  font: {
    size: 'var(--font-size-base)',
    weight: 'var(--font-weight-base)'
  },
  height: '35px',
  padding: 'var(--padding-base) 10px',
  placeholder: {
    color: {
      _: 'var(--color-gray-600)',
      disabled: 'var(--color-gray-400)'
    }
  },

  raw: {
    border: {
      _: '0',
      focus: '0',
      radius: '0'
    },
    box: {
      shadow: {
        focus: {
          _: '0 2px 0 var(--color-blue-400)',
          error: '0 2px 0 var(--color-red-400)',
          success: '0 2px 0 var(--color-green-400)'
        }
      }
    },
    height: '25px',
    padding: '2px 0 5px',
    outline: '0 !important'
  }
};
