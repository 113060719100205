<script>
  export let row = {};
</script>

<div class="tag" style="background-color: {row.bg}; color: {row.color};">
  {row.label}
</div>

<style lang="scss">
  .tag {
    border-radius: var(--border-radius-base);
    display: inline-flex;
    padding: var(--padding-small) var(--padding-base);
  }
</style>
