<script>
  import { onMount } from 'svelte';
  import { QueryParams } from '@client/utils/query-params';

  export let columns = [];
  export let rows = [];

  export let sortKey = '';
  export let sortOrder = 1;
  export let className = '';

  export let onSort = () => {};

  export let rowRendered = () => {
    return true;
  };

  const onColumnSort = (key) => {
    if (sortKey === key) {
      sortOrder = sortOrder * -1;
    } else {
      sortKey = key;
      sortOrder = 1;
    }

    QueryParams.set('field', sortKey);
    QueryParams.set('order', sortOrder);

    onSort(sortKey, sortOrder);
  };

  const initialize = () => {
    const fieldParam = QueryParams.get('field');
    const orderParam = QueryParams.get('order');

    if (fieldParam) {
      sortKey = fieldParam;
    }

    if (orderParam) {
      sortOrder = orderParam;
    }

    QueryParams.set('field', sortKey);
    QueryParams.set('order', sortOrder);
  };

  onMount(initialize);
</script>

<div class="grid {className}" {...$$props}>
  <div class="grid-head">
    {#each columns as column}
      <div
        class="grid-cell {column.class || ''}"
        class:sortable={column.sortable !== false}
        on:click={() => column.sortable !== false && onColumnSort(column.sortKey)}
      >
        <span>
          {column.label}
          {#if sortKey === column.sortKey}
            {#if sortOrder === 1}
              <i class="links-icon-arrow-up" />
            {:else}
              <i class="links-icon-arrow-down" />
            {/if}
          {/if}
        </span>
      </div>
    {/each}
  </div>
  <div class="grid-body">
    {#each rows as row}
      {#if rowRendered(row)}
        <div class="grid-row">
          {#each columns as column}
            <div class="grid-cell {column.class}">
              {#if column.renderComponent}
                <svelte:component
                  this={column.renderComponent.component || column.renderComponent}
                  {...column.renderComponent.props || {}}
                  {row}
                />
              {:else}
                {@html column.renderValue ? column.renderValue(row) : column.value(row)}
              {/if}
            </div>
          {/each}
        </div>
      {/if}
    {/each}
  </div>
</div>

<style lang="scss" src="./grid.scss"></style>
