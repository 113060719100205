<script>
  import { getContext } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Modal } from '@client/components/modal';
  import { appStore } from '@client/stores/app';

  import { CreateEdit } from '../index';

  const { prefix } = getContext('config');

  export let show = false;
  export let type = null;
  export let editing = false;
  export let uniqueLinkName = false;
  export let currentUser = null;
  export let tags = [];
  export let editors = [];
  export let onSave = function () {};
  export let onClose = function () {};
  export let link = {
    title: '',
    link: '',
    private: false,
    source: '',
    tags: [],
    editors: [],
    type: type || 'redirect'
  };

  $: maxWidth = $appStore.isMobile ? '540px' : link.type === 'redirect' ? '500px' : '800px';
</script>

<Modal bind:maxWidth bind:show>
  <div class="modals">
    <div class="modals-header">
      <h1>
        {#if editing}
          {$format('label.EDITING_X', { values: { item: `${prefix}/${link.link}` } })}
        {:else if link.link}
          {$format('label.CREATING_X', { values: { item: `${prefix}/${link.link}` } })}
        {:else if link.type === 'template'}
          {$format('label.NEW_TEMPLATE')}
        {:else if link.type === 'snippet'}
          {$format('label.NEW_SNIPPET')}
        {:else}
          {$format('label.NEW_SHORTCUT')}
        {/if}
      </h1>
    </div>
    <div class="modals-content">
      <CreateEdit
        bind:show
        bind:type
        bind:editing
        bind:uniqueLinkName
        bind:currentUser
        bind:editors
        bind:onSave
        bind:onClose
        bind:link
        bind:tags
      />
    </div>
  </div>
</Modal>

<style lang="scss" src="./edit.scss"></style>
