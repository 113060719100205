import { addMessages, init, locale } from 'svelte-i18n';
import { setDefaultOptions } from 'date-fns';
import * as locales from 'date-fns/locale';

import { LocalStorage } from '@client/utils/local-storage';

import en from './locales/en.json';
import es from './locales/es.json';
import zh from './locales/zh.json';

const lang = LocalStorage.get('locale');

if (!lang) {
  LocalStorage.update('locale', 'en');
} else {
  setDefaultOptions({
    locale: lang === 'zh' ? locales.zhCN || locales.enUS : locales[lang]
  });
}

// TODO: Future Kieran
// Switch to register() once code-splitting changes are merged
addMessages('en', en);
addMessages('es', es);
addMessages('zh', zh);

init({
  fallbackLocale: 'en',
  initialLocale: lang ?? 'en'
});

locale.subscribe((lang) => LocalStorage.update('locale', lang));
