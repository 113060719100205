import { StateField } from '@codemirror/state';
import { Decoration, EditorView } from '@codemirror/view';

const LIQUID_TAG_REGEXP = /\{\{.*?\}\}|\{%.*?%\}/g;

const createDecorations = (doc) => {
  const decorations = [];
  const text = doc.toString();

  let match = LIQUID_TAG_REGEXP.exec(text);

  while (match) {
    const start = match.index;
    const end = start + match[0].length;
    const range = Decoration.mark({ class: 'liquid-tag' }).range(start, end);

    decorations.push(range);

    match = LIQUID_TAG_REGEXP.exec(text);
  }

  return Decoration.set(decorations);
};

export const liquidStyles = EditorView.baseTheme({
  '.liquid-tag': {
    backgroundColor: '#eee2ff', //'#d8ffe8',
    borderRadius: '20px',
    color: '#5c71d7',
    fontFamily: 'Monospace',
    fontSize: '12px',
    lineHeight: '14px',
    padding: '2px 4px'
  },
  '.ͼ1b': {
    color: '#5c71d7'
  },
  '.ͼ1g': {
    color: '#1026809e'
  },
  '.ͼ1h': {
    color: '#eb6ea5'
  },
  '.ͼ18': {
    color: '#6f5698'
  },
  '.cm-line .liquid-tag::selection': {
    backgroundColor: 'rgba(0,0,0,.15) !important',
    borderRadius: 'var(--border-radius-base)'
  }
});

export const liquidHighlighting = StateField.define({
  create(state) {
    return createDecorations(state.doc);
  },
  update(decorations, tr) {
    if (tr.docChanged) {
      return createDecorations(tr.newDoc);
    }

    return decorations;
  },
  provide: (f) => EditorView.decorations.from(f)
});
