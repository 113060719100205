export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const secondsToHms = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);

  const hDisplay = h < 10 ? '0' + h : h;
  const mDisplay = m < 10 ? '0' + m : m;
  const sDisplay = s < 10 ? '0' + s : s;

  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const formatNumber = {
  commas(value, locale = 'en-US') {
    return value.toLocaleString(locale);
  },
  currency(value, locale = 'en-US', currency = 'USD') {
    return value.toLocaleString(locale, { style: 'currency', currency });
  }
};

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param oldValue The initial value
 * @param newValue The value that changed
 */
export const getPercentageIncrease = (oldValue, newValue) => {
  if (newValue === 0) {
    return oldValue * 100;
  }

  return ((oldValue - newValue) / newValue) * 100;
};

export const abbreviateNumber = (num, decimals = 2) => {
  if (num < 1000) {
    return num;
  }

  if (num < 1000000) {
    return (num / 1000).toFixed(decimals) + 'k';
  }

  if (num < 1000000000) {
    return (num / 1000000).toFixed(decimals) + 'M';
  }

  return (num / 1000000000).toFixed(decimals) + 'B';
};
