import { get, writable } from 'svelte/store';
import { defineAbility } from '@casl/ability';

import { updateBootstrap } from '@client/app/bootstrap';

export const userStore = writable(null);

const generateUserRoles = ({ admin, billing, shortcuts, shorturls, snippets, staff, tags, templates }) => {
  const superUser = admin || staff;
  const billingActions = billing && superUser ? ['manage'] : [];
  const shortcutsActions = shortcuts ? ['manage'] : [];
  const shorturlsActions = shorturls ? ['manage'] : [];
  const shorturlsInsightActions = shorturlsActions.length ? ['manage'] : [];
  const snippetsActions = snippets ? ['manage'] : [];
  const staffActions = staff ? ['manage'] : [];
  const tagsActions = tags ? ['manage'] : [];
  const templatesActions = templates ? ['manage'] : [];
  const usersActions = superUser ? ['manage'] : [];
  const insightActions = shortcutsActions.length || snippetsActions.length || templatesActions.length ? ['manage'] : [];
  const favoritesActions =
    shortcutsActions.length || snippetsActions.length || templatesActions.length ? ['manage'] : [];
  const inviteActions = superUser ? ['manage'] : [];
  const integrationsActions = superUser ? ['manage'] : [];

  return {
    billing: billingActions,
    insights: insightActions,
    shortcuts: shortcutsActions,
    snippets: snippetsActions,
    templates: templatesActions,
    favorites: favoritesActions,
    invites: inviteActions,
    integrations: integrationsActions,
    staff: staffActions,
    users: usersActions,
    'users:create': usersActions,
    shorturls: shorturlsActions,
    'shorturls:insights': shorturlsInsightActions,
    tags: tagsActions,
    'tags:create': usersActions
  };
};

export const ability = (config) => {
  const types = Object.keys(config);

  return defineAbility((can) => {
    types.forEach((type) => {
      if (type === 'roles') {
        const definitions = config[type];
        const roles = Object.keys(definitions);

        roles.forEach((role) => {
          can(definitions[role], role);
        });
      }

      if (type === 'features') {
        const features = config[type];

        features.forEach((feature) => {
          can(`feature:${feature}`);
        });
      }
    });
  });
};

const reduce = (config) =>
  Object.keys(config)
    .map((key) => config[key] && key)
    .filter(Boolean);

export const processLoggedInUser = (user) => {
  const domain = user.domainInfo;
  const personal = !Object.keys(domain).length;
  const favorites = new Set(user.bookmarks);
  const features = personal ? [] : reduce(domain.features);
  const staff = user.domain.split('@').pop() === 'colinks.io';
  const productRoles = personal ? { shortcuts: true, shorturls: false, snippets: true, templates: true } : domain.roles;
  const admin = personal ? true : domain.admin.includes(user.email);

  updateBootstrap('domain', user.domain);

  const roles = generateUserRoles({
    ...productRoles,
    admin,
    tags: true,
    staff
  });

  return {
    ...user,
    ability: ability({
      features,
      roles
    }),
    favorites,
    features,
    staff,
    roles
  };
};

export const hasCapability = (action, subject) => {
  if (!subject) {
    return get(userStore)?.ability?.can(action);
  }

  return get(userStore)?.ability?.can(action, subject);
};
