const NAME_SPLIT_REGEXP = /[\.-_]/g;

export const initialsFromEmail = (text = '') => {
  const user = text.split('@');
  const name = user[0].split(NAME_SPLIT_REGEXP);

  if (name.length > 1) {
    return name[0].slice(0, 1) + name[1].slice(0, 1);
  }

  return name[0].slice(0, 2);
};

export const initialsFromName = (text = '') => {
  const name = text.split(' ');

  if (name.length > 1) {
    return name[0].slice(0, 1) + name[1].slice(0, 1);
  }

  return name[0].slice(0, 2);
};

export const nameFromEmail = (text = '') => {
  const user = text.split('@');
  const name = user[0].split(NAME_SPLIT_REGEXP);

  if (name.length > 1) {
    const [first, last] = name;

    return first.charAt(0).toUpperCase() + first.slice(1) + ' ' + last.charAt(0).toUpperCase() + last.slice(1);
  }

  return name[0].charAt(0).toUpperCase() + name[0].slice(1);
};
