<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';

  import { getNotificationsContext } from '@client/components/notifications';
  import { Input } from '@client/components/input';
  import { Modal } from '@client/components/modal';
  import { Select } from '@client/components/select';

  import { UsersService } from '@client/services/users';

  import { detailedDiff } from '@common/utils/objects';

  const { addNotification } = getNotificationsContext();

  export let show = false;
  export let editing = false;
  export let onSave = function () {};
  export let onClose = function () {};

  export let user = {
    name: '',
    email: '',
    role: ''
  };

  let maxWidth = '400px';
  let submitButton;

  let originalUserObject = null;

  let roleTypes = [
    { value: 'admin', label: $format('label.ADMIN') },
    { value: 'user', label: $format('label.USER') },
    { value: 'viewer', label: $format('label.VIEWER') }
  ];

  const onTypeChange = ({ detail }) => {
    user.role = detail.value;
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitButton.click();
    }
  };

  const onUpdate = async () => {
    const diff = detailedDiff(originalUserObject, user);
    const action = editing ? 'update' : 'create';

    await UsersService[action](user);

    console.log(diff, action, originalUserObject, user);

    const label = editing ? 'label.NOTIFICATION.USER_UPDATE_SUCCESS' : 'label.NOTIFICATION.USER_CREATE_SUCCESS';

    const text = $format(label, {
      values: {
        item: user.name
      }
    });

    addNotification({
      text,
      header: action === 'update' ? $format('label.UPDATED') : $format('label.CREATED'),
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });

    onSave(true);

    originalUserObject = null;

    onClose();
  };

  $: if (user && originalUserObject === null) {
    originalUserObject = JSON.parse(JSON.stringify(user));
  }

  $: if (!show) {
    user = {
      name: '',
      email: '',
      role: ''
    };
  }

  $: isFormValid = user.role.length && originalUserObject !== user;
</script>

<Modal bind:maxWidth bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>
        {#if editing}
          {$format('label.EDITING_X', { values: { item: user.name } })}
        {:else if user.name}
          {$format('label.CREATING_X', { values: { item: user.name } })}
        {:else}
          {$format('label.CREATE_NEW_USER')}
        {/if}
      </h1>
    </div>
    <div class="modals--content" on:keyup={onKeyUp} role="button" tabindex="0">
      <div class="field">
        <label for="user-name">{$format('label.NAME')}</label>
        <div class="user-name-input" class:disabled={editing}>
          <Input type="text" id="user-name" class="fill" bind:value={user.name} on:keyup={onKeyUp} autofocus />
        </div>
      </div>
      <div class="field">
        <label for="types">
          {$format('label.ROLE')}
        </label>
        <Select
          items={roleTypes}
          value={user.role}
          placeholder="Select the users role"
          class="select-element"
          searchable
          showChevron
          clearable={false}
          on:change={onTypeChange}
        >
          <svelte:fragment slot="item" let:item>
            <div class="item">
              <div class="item-detail">
                <div>{item.label}</div>
              </div>
            </div>
          </svelte:fragment>
        </Select>
      </div>
    </div>
    <div class="modals--footer">
      <Button className="mr-md" bind:ref={submitButton} on:click={onUpdate} disabled={!isFormValid}>
        {#if !editing}
          {$format('label.SAVE')}
        {:else}
          {$format('label.UPDATE')}
        {/if}
      </Button>
      <Button kind="link" on:click={onClose}>{$format('label.CANCEL')}</Button>
    </div>
  </div>
</Modal>

<style lang="scss" src="./edit.scss"></style>
