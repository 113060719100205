import { APITransport } from '@client/transports/api';

export const UsersService = {
  getAll(query = {}) {
    return APITransport.get({
      path: '/v1/users',
      query
    });
  },

  get() {
    return APITransport.get({
      path: '/v1/users/me'
    });
  },

  create(body) {
    return APITransport.post({
      path: '/v1/users',
      body
    });
  },

  delete({ links, tags, urls, user }) {
    return APITransport.delete({
      path: `/v1/users/${user}`,
      body: { links, tags, urls }
    });
  },

  update({ name, role, user }) {
    return APITransport.put({
      path: `/v1/users/${user}`,
      body: {
        role,
        name
      }
    });
  }
};
