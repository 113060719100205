<script>
  import { format, locale, locales } from 'svelte-i18n';
  import { Col, Container, Row } from 'sveltestrap';

  import { setDefaultOptions } from 'date-fns';
  import * as localess from 'date-fns/locale';

  import { currentThemeName, themes, setTheme } from '@client/components/theme';
  import { getNotificationsContext } from '@client/components/notifications';
  import { Loader } from '@client/components/loader';

  import { LOCALES } from '@client/enums/locales';

  import { base } from '@client/app/themes/base';

  const { addNotification } = getNotificationsContext();

  let loaded = true;

  const onThemeUpdate = (e) => {
    const value = e.target.value;
    const theme = $themes[value].meta.name;

    setTheme(value);

    addNotification({
      header: $format('label.UPDATED'),
      text: $format('label.NOTIFICATION.THEME_CHANGE', { values: { theme } }),
      type: 'success',
      autohide: 2500,
      position: 'top-right'
    });
  };

  const onLocaleUpdate = (e) => {
    const value = e.target.value;
    const language = LOCALES[value];

    locale.set(value);

    setDefaultOptions({
      locale: value === 'zh' ? localess.zhCN || localess.enUS : localess[value]
    });

    addNotification({
      header: $format('label.UPDATED'),
      text: $format('label.NOTIFICATION.LANGUAGE_CHANGE', { values: { language } }),
      type: 'success',
      autohide: 2500,
      position: 'top-right'
    });
  };

  $: languages = $locales.map((value) => ({ label: LOCALES[value] || value, value }));
  $: themess = Object.keys($themes).map((value) => ({ label: $themes[value].meta.name, value }));
</script>

<div class="page settings">
  <Loader show={!loaded} />

  <div class="page-header no-action">
    <div class="row">
      <div class="col-md-12">
        <h1>{$format('label.SETTINGS')}</h1>
      </div>
    </div>
  </div>

  <div class="page-content">
    <Container fluid class="pad--top--xxlarge pad--bottom--xxlarge">
      <h2>{$format('label.GENERAL')}</h2>
      <Row>
        <Col>
          <div class="field">
            <label for="language">{$format('label.LANGUAGE')}</label>
            <div class="select">
              <select id="language" on:change={onLocaleUpdate}>
                {#each languages as language}
                  <option value={language.value} selected={$locale === language.value}>{language.label}</option>
                {/each}
              </select>
            </div>
          </div>
          <div class="field">
            <label for="theme">{$format('label.THEME')}</label>
            <div class="theme-selection">
              {#each themess as theme}
                {@const sidebar = $themes[theme.value].sidebar}
                <div class="theme-option" class:active={$currentThemeName === theme.value}>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <div
                    class="mini-theme"
                    on:click={() => onThemeUpdate({ target: { value: theme.value } })}
                    role="button"
                    tabindex="0"
                  >
                    <div
                      class="mini-sidebar"
                      style="background-color: {sidebar?.background?.color || base.sidebar?.background?.color};"
                    >
                      <div class="mini-header">
                        <div class="mini-logo" />
                      </div>
                      <div class="mini-list">
                        <div class="mini-list-item">
                          <div
                            class="mini-list-item-icon"
                            style="background-color: {sidebar?.section?.item?.icon?.background?.color?._ ||
                              'rgba(200,200,200,.25)'};"
                          />
                          <div
                            class="mini-list-item-text"
                            style="background-color: {sidebar?.section?.item?.color?._ ||
                              base.sidebar?.section?.item?.color?._}; width: 70%;"
                          />
                        </div>
                        <div class="mini-list-item">
                          <div
                            class="mini-list-item-icon"
                            style="background-color: {sidebar?.section?.item?.backgroundColor?.active ||
                              base.sidebar?.section?.item?.backgroundColor?.active};"
                          />
                          <div
                            class="mini-list-item-text"
                            style="background-color: {sidebar?.section?.heading?.color?.active ||
                              base.sidebar?.section?.heading?.color?.active}; width: 60%;"
                          />
                        </div>
                        <div class="mini-list-item">
                          <div
                            class="mini-list-item-icon"
                            style="background-color: {sidebar?.section?.item?.icon?.background?.color?._ ||
                              'rgba(200,200,200,.25)'};"
                          />
                          <div
                            class="mini-list-item-text"
                            style="background-color: {sidebar?.section?.heading?.color?._ ||
                              base.sidebar?.section?.heading?.color?._};  width: 55%;"
                          />
                        </div>
                        <div class="mini-list-item">
                          <div
                            class="mini-list-item-icon"
                            style="background-color: {sidebar?.section?.item?.icon?.background?.color?._ ||
                              'rgba(200,200,200,.25)'};"
                          />
                          <div
                            class="mini-list-item-text"
                            style="background-color: {sidebar?.section?.heading?.color?._ ||
                              base.sidebar?.section?.heading?.color?._}; width: 40%;"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mini-content">
                      <div class="mini-toggle" />
                    </div>
                  </div>
                  <div class="theme-name">
                    {$themes[theme.value].meta.name}
                  </div>
                </div>
              {/each}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
</div>

<style lang="scss" src="./settings.scss"></style>
