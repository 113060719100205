/* eslint-disable */
import { addDays, format, parseISO as parse, intervalToDuration, subDays } from 'date-fns';

export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export const parseISO = parse;

export const isDate = (value) => value instanceof Date && !isNaN(value);

export const formatDate = (date, formatOutput = ' h:mm aa') => {
  if (!date || !formatOutput) {
    return '-';
  }

  if (typeof date === 'string') {
    date = parseISO(date);
  }

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return format(date, formatOutput);
};

export const getDateObject = (date) => {
  if (typeof date === 'string') {
    if (!date.endsWith('Z')) {
      date = date + ' UTC';
    }

    return new Date(date);
  }

  return date;
};

const formatDistanceLocale = {
  lessThanXSeconds: '{count}s',
  xSeconds: '{count}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{count}m',
  xMinutes: '{count}m',
  aboutXHours: '{count}h',
  xHours: '{count}h',
  xDays: '{count}d',
  aboutXWeeks: '{count}w',
  xWeeks: '{count}w',
  aboutXMonths: '{count}mth',
  xMonths: '{count}mth',
  aboutXYears: '{count}y',
  xYears: '{count}y',
  overXYears: '{count}y',
  almostXYears: '{count}y'
};

export const formatDistance = (token, count, options = {}) => {
  const result = formatDistanceLocale[token].replace('{count}', count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
};

export const daysBetweenDates = (first, second) => {
  const diff = second.getTime() - first.getTime();
  return diff / (1000 * 60 * 60 * 24);
};

export const getDatesBetween = (startDate, endDate) => {
  const dates = [];
  const end = addDays(getDateObject(endDate), 1);
  let start = addDays(getDateObject(startDate), 1);

  while (start <= end) {
    dates.push(format(start, 'yyyy-MM-dd'));
    start = addDays(start, 1);
  }

  return dates;
};

export const getDateFromToday = (days) => {
  return Date.now() - days * MILLISECONDS_IN_DAY;
};

export const normalizeTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export const durationFromSeconds = (value) => {
  const duration = intervalToDuration({ start: 0, end: Number(value) * 1000 });

  let formatted = '';

  if (duration.hours) {
    formatted += duration.hours + 'hr ';
  }

  if (duration.minutes) {
    formatted += duration.minutes + 'm ';
  }

  if (duration.seconds) {
    formatted += duration.seconds + 's';
  }

  if (!formatted.length) {
    formatted += value + 's';
  }

  return formatted;
};

export const adjustForTimeZone = (date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const generateDateRange = (start, end) => {
  let rangeStartDate = new Date(start);

  const endDate = new Date(end);
  const dateRange = [];

  while (rangeStartDate <= endDate) {
    dateRange.push(rangeStartDate.getTime());
    rangeStartDate = subDays(rangeStartDate, -1);
  }

  return dateRange;
};

export const generateBreakoutsDateRange = (start, end) => {
  let rangeStartDate = new Date(start);

  const endDate = new Date(end);
  const dateRange = [];

  while (rangeStartDate <= endDate) {
    dateRange.push({ date: rangeStartDate.getTime(), counts: [] });
    rangeStartDate = subDays(rangeStartDate, -1);
  }

  return dateRange;
};

export const backfillDates = (metrics, startDate, endDate) => {
  const dateRange = generateDateRange(startDate, endDate);

  return dateRange.map((date) => {
    const existingData = metrics.find((data) => format(new Date(data[0]), 'MM/dd/yyyy') === format(date, 'MM/dd/yyyy'));
    return existingData || [date, 0];
  });
};

export const backfillBreakoutDates = (data, startDate, endDate) => {
  const groupedByDate = data.reduce((acc, curr) => {
    const { x, y, z, raw } = curr;
    const formattedDate = format(new Date(x), 'M/d/yyyy');

    if (!acc[formattedDate]) {
      acc[formattedDate] = { date: formattedDate, data: [] };
    }

    acc[formattedDate].data.push({ z, y, raw });

    return acc;
  }, {});

  const dateRange = generateBreakoutsDateRange(startDate, endDate);

  const backfilledData = dateRange.map(({ date }) => {
    const formattedDate = format(new Date(date), 'M/d/yyyy');

    if (groupedByDate[formattedDate]) {
      return {
        date: formattedDate,
        data: groupedByDate[formattedDate].data
      };
    } else {
      return {
        date: formattedDate,
        data: []
      };
    }
  });

  return backfilledData;
};
