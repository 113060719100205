<script>
  import { format } from 'svelte-i18n';
  import { tooltip } from '@svelte-plugins/tooltips';

  export let row = {};
</script>

<div class="visible-state text-align--center">
  {#if row.private}
    <i
      class="links-icon-eye-off"
      alt={$format('label.PRIVATE_LINK')}
      title={$format('label.PRIVATE_LINK_TOOLTIP')}
      use:tooltip={{
        position: 'right',
        content: $format('label.PRIVATE_LINK_TOOLTIP')
      }}
    />
  {:else}
    <i
      class="links-icon-eye"
      alt={$format('label.PUBLIC_LINK')}
      title={$format('label.PUBLIC_LINK_TOOLTIP')}
      use:tooltip={{
        position: 'right',
        content: $format('label.PUBLIC_LINK_TOOLTIP')
      }}
    />
  {/if}
</div>

<style lang="scss">
  .visible-state {
    [class*='links-icon-'] {
      cursor: help;
    }

    .links-icon-eye-off {
      color: var(--color-red-400);
    }
  }
</style>
