<script>
  export let checked = false;
  export let ref = null;
  export let disabled = null;
  export let onLabel = '';
  export let offLabel = '';
</script>

<label class="switch">
  <input bind:this={ref} type="checkbox" bind:checked aria-disabled={disabled} {disabled} />
  <span class="slider" />
</label>

{#if onLabel && offLabel}
  <span>
    {#if checked}
      {onLabel}
    {:else}
      {offLabel}
    {/if}
  </span>
{/if}

<style lang="scss" src="./switch.scss"></style>
