import { APITransport } from '@client/transports/api';

export const AuthService = {
  checkProviders(query = {}) {
    return APITransport.get({
      path: '/auth/providers',
      query
    });
  },

  login(code, type, oktadomain) {
    return APITransport.post({
      path: `/auth/${type}`,
      query: {
        code,
        oktadomain
      }
    });
  },

  logout() {
    return APITransport.post({
      baseURL: '',
      path: '/logout'
    });
  }
};
