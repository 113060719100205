<script>
  import { Tooltip } from 'sveltestrap';

  import { Porthole } from '@client/components/porthole';

  export let row = {};
</script>

<div style="text-align--center">
  <div id="tooltip-person-{row._id}" class="img">
    <Porthole details={{ ...row.createdBy }} size="medium" />
  </div>
  <Tooltip target="tooltip-person-{row._id}" placement="top">
    {row.createdBy.name}
  </Tooltip>
</div>

<style lang="scss">
  .img {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
</style>
