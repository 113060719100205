import { format } from 'date-fns';

import { adjustForTimeZone, backfillDates } from '@common/utils/dates';

// Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
let seed = Date.now();

function rand(min = 0, max = 0) {
  seed = (seed * 9301 + 49297) % 233280;
  return min + (seed / 233280) * (max - min);
}

function numbers(config = {}) {
  const { min = 0, max = 100, from = [], count = 8, decimals = 8, continuity = 1 } = config;
  const dfactor = Math.pow(10, decimals) || 0;
  const data = [];
  let value;

  for (let i = 0; i < count; ++i) {
    value = (from[i] || 0) + rand(min, max);
    if (rand() <= continuity) {
      data.push(Math.round(dfactor * value) / dfactor);
    } else {
      data.push(null);
    }
  }

  return data;
}

const DATA_CONFIG = {
  count: 12,
  min: 0,
  max: 100
};

const DEFAULT_CONFIG = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  count: 12,
  min: 0,
  max: 100,
  datasets: [
    {
      label: 'Dimension',
      fill: false,
      borderColor: 'rgb(54, 162, 235)',
      backgroundColor: 'rgb(54, 162, 235)'
    },
    {
      label: 'Dimension',
      fill: false,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgb(255, 99, 132)'
    }
  ]
};

export const generateData = (config = DEFAULT_CONFIG) => {
  const { count, labels, min, max, datasets } = config;

  const sets = datasets.map(({ label, fill = false, borderColor, backgroundColor, dataConfig = DATA_CONFIG }) => ({
    label,
    data: numbers(dataConfig),
    fill,
    borderColor,
    backgroundColor
  }));

  return {
    labels,
    count,
    min,
    max,
    datasets: sets
  };
};

// TODO: Make this generic for passing x/y axis keys
export const generateMetricDataset = (startDateRange, endDateDateRange, series, key, label, color) => {
  const labels = [];
  const points = [];
  const values = series.map((item) => [adjustForTimeZone(new Date(item.date)), item[key]]);
  const range = backfillDates(values, startDateRange, endDateDateRange);

  const data = range.reduce((acc, curr) => {
    const formattedLabel = format(curr[0], 'EEE, MMM d, yyyy');

    if (!acc[formattedLabel]) {
      acc[formattedLabel] = 0;
    }

    acc[formattedLabel] += curr[1];

    return acc;
  }, {});

  const sortedSeries = Object.keys(data)
    .map((dateString) => new Date(dateString))
    .sort((a, b) => a - b);

  const sortedSeriesObject = sortedSeries.reduce((acc, date) => {
    const dateString = format(date, 'EEE, MMM d, yyyy');

    acc[dateString] = data[dateString];

    return acc;
  }, {});

  Object.keys(sortedSeriesObject).forEach((date) => {
    labels.push(date);
    points.push(sortedSeriesObject[date]);
  });

  if (series?.length) {
    return {
      labels,
      datasets: [
        {
          label,
          backgroundColor: 'rgba(225, 204,230, .1)',
          borderColor: color,
          pointColor: color,
          fill: true,
          tension: 0.3,
          data: points
        }
      ]
    };
  }

  return null;
};
