<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';
  import { Tooltip } from 'sveltestrap';

  import { subDays, format as formatDate } from 'date-fns';
  import { DatePicker } from '@client/components/datepicker';

  import { Loader } from '@client/components/loader';
  import { Select } from '@client/components/select';
  import { SparkChart } from '@client/components/charts';

  import { ReportsService } from '@client/services/reports';
  import { UrlsService } from '@client/services/urls';

  import { abbreviateNumber } from '@common/utils/numbers';
  import { adjustForTimeZone } from '@common/utils/dates';
  import { backfillDates, backfillBreakoutDates } from '@common/utils/dates';
  import { generateMetricDataset } from '@client/utils/charts/generator';

  import { COLOR_PALETTE } from '@client/enums';
  import { Chart } from './charts';

  const today = new Date();

  let isOpen = false;
  let loading = false;

  let colorMap = {};
  let colorIndex = 0;

  let dateFormat = 'MMM d, yyyy';

  let startDate = subDays(today, 29);
  let endDate = today;

  let formattedStartDate = '';
  let formattedEndDate = '';

  let startDateRange = formatDate(startDate, 'MM/dd/yyyy');
  let endDateRange = formatDate(new Date(), 'MM/dd/yyyy');

  let urls = [];
  let shortUrls = [];
  let selectedShortUrls = [];
  let maxSelectedShortUrls = 5;

  let os = { data: [], loading: false };
  let browsers = { data: [], loading: false };
  let countries = { data: [], loading: false };
  let cities = { data: [], loading: false };
  let regions = { data: [], loading: false };
  let referrers = { data: [], loading: false };
  let paths = { data: [], loading: false };
  let broken = { data: [], loading: false };
  let devices = { data: [], loading: false };
  let metrics = { data: [], loading: false };
  let clicks = { data: [], loading: false };
  let links = { data: [], loading: false };

  let kpis = {
    heading: {
      clicks: {
        title: $format('label.CLICKS'),
        description: $format('label.TOOLTIP.KPI_CLICKS')
      },
      averageClicks: {
        title: $format('label.AVG_CLICKS'),
        description: $format('label.TOOLTIP.KPI_AVG_CLICKS')
      },
      activeUsers: {
        title: $format('label.USERS'),
        description: $format('label.TOOLTIP.KPI_USERS')
      },
      newUrls: {
        title: $format('label.NEW_URLS'),
        description: $format('label.TOOLTIP.KPI_NEW_URLS')
      },
      brokenUrls: {
        title: $format('label.BROKEN_URLS'),
        description: $format('label.TOOLTIP.KPI_BROKEN_URLS')
      }
    },
    data: {},
    loading: false
  };

  const sparkConfigs = {
    clicks: {
      key: 'clicks',
      color: '#d9bc61',
      label: $format('label.CLICKS'),
      data: []
    },
    averageClicks: {
      key: 'averageClicks',
      color: '#92e2C0',
      label: $format('label.AVG_CLICKS'),
      data: []
    },
    newUrls: {
      key: 'newUrls',
      color: '#43a0fc',
      label: $format('label.NEW_URLS'),
      data: []
    },
    brokenUrls: {
      key: 'brokenUrls',
      color: '#e01d5a',
      label: $format('label.BROKEN_URLS'),
      data: []
    }
  };

  const toggleDateRangePicker = () => (isOpen = !isOpen);

  const assignColorsToUrls = () => {
    (selectedShortUrls || [])
      .map(({ label }) => label)
      .forEach((url) => {
        if (!colorMap[url]) {
          colorMap[url] = {
            backgroundColor: COLOR_PALETTE[colorIndex % COLOR_PALETTE.length],
            hoverBackgroundColor: COLOR_PALETTE[colorIndex % COLOR_PALETTE.length].replace('.99', '0.8')
          };

          colorIndex++;
        }
      });

    colorMap = { ...colorMap };

    return colorMap;
  };

  const onContextChange = () => {
    urls = (selectedShortUrls || []).map(({ value }) => value);
    onDateChange();
  };

  const formattedDate = (dateString) => (dateString && formatDate(new Date(dateString), dateFormat)) || '';

  const onDateChange = () => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    startDateRange = formatDate(startDate, 'MM/dd/yyyy');
    endDateRange = formatDate(endDate, 'MM/dd/yyyy');
    colorMap = {};
    colorIndex = 0;
    assignColorsToUrls();
    getReports();
  };

  const getReportData = async (report, config) => {
    config.loading = true;

    const result = await ReportsService.getShorturlReport({
      report,
      startDate,
      endDate,
      links: urls
    });

    config.loading = false;

    return result;
  };

  const getReport = async (report) => {
    if (report === 'kpis') {
      const [kpiConfig] = await getReportData(report, kpis);
      kpis.data = kpiConfig;
    } else if (report === 'clicks') {
      clicks.data = [];
      const data = await getReportData(report, clicks);

      if (urls.length) {
        const dataset = backfillBreakoutDates(data, startDateRange, endDateRange);
        clicks.data = dataset;
      } else {
        if (!data.length) {
          clicks.data = [];
        } else {
          const grouped = data.reduce((acc, curr) => {
            const { x, y } = curr;

            if (!acc[x]) {
              acc[x] = 0;
            }

            acc[x] += y;

            return acc;
          }, {});
          const values = Object.values(grouped);
          const keys = Object.keys(grouped);

          const v = values.map((y, index) => [adjustForTimeZone(new Date(keys[index])), y]);
          const range = backfillDates(v, startDateRange, endDateRange);
          clicks.data = range.map(([date, y]) => ({ x: formatDate(date, 'M/d/yyyy'), y }));
        }
      }
    } else if (report === 'metrics') {
      const data = await getReportData(report, metrics);

      metrics.data = data;

      Object.keys(sparkConfigs).forEach((key) => {
        sparkConfigs[key].data = generateMetricDataset(
          startDateRange,
          endDateRange,
          metrics.data,
          sparkConfigs[key].key,
          sparkConfigs[key].label,
          sparkConfigs[key].color
        );
      });
    } else {
      if (report === 'os') {
        os.data = await getReportData(report, os);
      }

      if (report === 'browsers') {
        browsers.data = await getReportData(report, browsers);
      }

      if (report === 'countries') {
        countries.data = await getReportData(report, countries);
      }

      if (report === 'cities') {
        cities.data = await getReportData(report, cities);
      }

      if (report === 'regions') {
        regions.data = await getReportData(report, regions);
      }

      if (report === 'referrers') {
        referrers.data = await getReportData(report, referrers);
      }

      if (report === 'paths') {
        paths.data = await getReportData(report, paths);
      }

      if (report === 'broken') {
        broken.data = await getReportData(report, broken);
      }

      if (report === 'devices') {
        devices.data = await getReportData(report, devices);
      }

      if (report === 'links') {
        links.data = await getReportData(report, links);
      }
    }
  };

  const getReports = async () => {
    const reports = [
      'os',
      'browsers',
      'countries',
      'cities',
      'regions',
      'referrers',
      'paths',
      'broken',
      'devices',
      'kpis',
      'metrics',
      'clicks',
      'links'
    ];

    await Promise.all(reports.map(getReport));
  };

  getReports();

  onMount(async () => {
    const { records } = await UrlsService.getAll({ perpage: 1000 });

    shortUrls = records.map(({ title, shortHash, domainHash }) => ({
      label: title,
      value: `${domainHash}/${shortHash}`
    }));

    COLOR_PALETTE.forEach((m, index) => {
      document.documentElement.style.setProperty(`--dataset-color-${index}`, COLOR_PALETTE[index]);
    });
  });

  $: formattedStartDate = formattedDate(startDate);
  $: formattedEndDate = formattedDate(endDate);
  $: shortUrlsItems = maxSelectedShortUrls === urls.length ? [] : shortUrls;
</script>

<div class="page audience">
  <Loader backdrop show={loading} />
  <div class="page-header no-action">
    <div class="row">
      <div class="col-md-12">
        <h1>{$format('label.INSIGHTS_DASHBOARD')}</h1>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="context-bar">
      <div class="comparison-select">
        <Select
          bind:items={shortUrlsItems}
          bind:value={selectedShortUrls}
          on:change={onContextChange}
          on:clear={onContextChange}
          placeholder={urls.length === maxSelectedShortUrls
            ? $format('label.PLACEHOLDER_CONTEXT_MAX_SELECTIONS')
            : $format('label.PLACEHOLDER_CONTEXT_CHOOSE', { values: { type: 'short URLs' } })}
          clearable={false}
          searchable
          multiple
        >
          <svelte:fragment slot="item" let:item>
            <div class="item">
              <div class="label">{item.label}</div>
              <div class="value">{item.value}</div>
            </div>
          </svelte:fragment>
        </Select>
      </div>
      <div class="date-filter">
        <DatePicker bind:isOpen bind:startDate bind:endDate isRange {onDateChange} showPresets align="right">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div class="date-field" on:click={toggleDateRangePicker} class:open={isOpen} role="button" tabindex="0">
            <i class="icon-calendar" />
            <div class="date">
              {formattedStartDate} - {formattedEndDate}
            </div>
          </div>
        </DatePicker>
      </div>
    </div>
    {#if !kpis.data.length}
      <div class="metrics-summary-wrap">
        <div class="metrics-summary">
          {#each Object.keys(kpis.data) as key}
            {#if !key.includes('Change') && key !== 'activeUsers'}
              {@const change = kpis.data[`${key}Change`]}
              <div class="metric">
                <div class="metric-label">
                  <span>{kpis.heading[key].title}</span>
                  <i class="links-icon-help-circle" id="tooltip-{key}" />
                  <Tooltip target="tooltip-{key}" placement="top">
                    <b>{kpis.heading[key].title}</b>
                    <div>{kpis.heading[key].description}</div>
                  </Tooltip>
                </div>
                <div class="metric-value" title={kpis.data[key].toLocaleString()}>
                  <div class="metric-value-total">
                    <span>{abbreviateNumber(kpis.data[key].toFixed(), 1)}</span>

                    {#if change !== 0}
                      <span class="change" class:negative={change < 0}>
                        <span>{change.toFixed()}%</span>
                        <i class:links-icon-trending-down={change < 0} class:links-icon-trending-up={change > 0}></i>
                      </span>
                    {/if}
                  </div>
                  {#if sparkConfigs[key]?.data?.datasets}
                    <SparkChart bind:data={sparkConfigs[key].data} height="25px" />
                  {/if}
                </div>
              </div>
            {/if}
          {/each}
        </div>
      </div>
    {/if}
    <div class="trend">
      <Chart
        bind:data={clicks.data}
        bind:loading={clicks.loading}
        bind:breakout={urls.length}
        {startDateRange}
        {endDateRange}
        hideTable
        bind:colorMap
        type="clicks"
        title={$format('label.CLICK_ENGAGEMENT')}
      />
    </div>
    <div class="chart-grid">
      {#if !urls.length}
        <Chart
          bind:data={links.data}
          bind:loading={links.loading}
          bind:breakout={urls.length}
          bind:colorMap
          type="links"
          title={$format('label.TOP_SHORT_URLS')}
        />
      {/if}
      <Chart
        bind:data={browsers.data}
        bind:loading={browsers.loading}
        bind:breakout={urls.length}
        bind:colorMap
        type="browsers"
        title={$format('label.TOP_BROWSERS')}
      />
      <Chart
        bind:data={os.data}
        bind:loading={os.loading}
        bind:breakout={urls.length}
        bind:colorMap
        type="os"
        title={$format('label.OPERATING_SYSTEMS')}
      />
      <Chart
        bind:data={devices.data}
        bind:loading={devices.loading}
        bind:breakout={urls.length}
        bind:colorMap
        type="devices"
        title={$format('label.TOP_DEVICES')}
      />
      <Chart
        bind:data={countries.data}
        bind:loading={countries.loading}
        bind:breakout={urls.length}
        bind:colorMap
        type="countries"
        title={$format('label.TOP_COUNTRIES')}
      />
      <Chart
        bind:data={cities.data}
        bind:loading={cities.loading}
        bind:breakout={urls.length}
        bind:colorMap
        type="cities"
        title={$format('label.TOP_CITIES')}
      />
      <Chart
        bind:data={regions.data}
        bind:loading={regions.loading}
        bind:breakout={urls.length}
        bind:colorMap
        type="regions"
        title={$format('label.TOP_REGIONS')}
      />
      <Chart bind:data={paths.data} bind:loading={paths.loading} type="paths" title={$format('label.TOP_PATHS')} />
      <Chart
        bind:data={referrers.data}
        bind:loading={referrers.loading}
        type="referrers"
        title={$format('label.TOP_REFERRERS')}
      />
      <Chart
        bind:data={broken.data}
        bind:loading={broken.loading}
        type="broken"
        title={$format('label.TOP_BROKEN_URLS')}
      />
    </div>
  </div>
</div>

<style lang="scss" src="./audience.scss"></style>
