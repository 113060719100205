import { APITransport } from '@client/transports/api';

export const ReportsService = {
  getShorturlReport({ report, ...rest }) {
    return APITransport.get({
      path: '/v1/reports/shorturls',
      query: {
        report,
        ...rest
      }
    });
  },
  getLinkReport({ report, ...rest }) {
    return APITransport.get({
      path: '/v1/reports/links',
      query: {
        report,
        ...rest
      }
    });
  },
  getViewReport({ report, ...rest }) {
    return APITransport.get({
      path: '/v1/reports/views',
      query: {
        report,
        ...rest
      }
    });
  },
  getUserReport({ limit = 5 }) {
    return APITransport.get({
      path: '/v1/reports/users',
      query: {
        report: 'top',
        limit
      }
    });
  }
};
