<script>
  import { onMount } from 'svelte';
  import { format } from 'svelte-i18n';
  import { Chart } from '@client/components/charts';
  import { Spinner } from '@client/components/spinner';

  import { deepCopy } from '@common/utils/objects';
  import { durationFromSeconds } from '@common/utils/dates';

  import { generateChartsConfig } from '@client/utils/charts';

  import { COLOR_PALETTE } from '@client/enums';

  export let height = '300px';
  export let width = '100%';
  export let data = [];
  export let title = '';
  export let type = 'browser';
  export let loading = false;
  export let hideTable = false;
  export let breakout = false;
  export let colorMap = {};

  const heading = {
    browsers: $format('label.BROWSER'),
    clicks: $format('label.DATE'),
    devices: $format('label.DEVICE'),
    os: $format('label.OS'),
    countries: $format('label.COUNTRY'),
    cities: $format('label.CITY'),
    referrers: $format('label.REFERRER'),
    paths: $format('label.PATH'),
    regions: $format('label.REGION'),
    broken: $format('label.BROKEN'),
    tags: $format('label.TAG'),
    users: $format('label.NAME'),
    links: $format('label.LINK'),
    time: $format('label.LINK')
  };

  const truncate = (text, maxLength = 15) => {
    if (text.length > maxLength) {
      text = text.substring(0, maxLength) + '...';
    }

    return text;
  };

  let labels = [];

  let defaultDatasetConfig = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [...COLOR_PALETTE],
        hoverBackgroundColor: [...COLOR_PALETTE.map((item) => item.replace('.99', '0.8'))]
      }
    ]
  };

  let chartData = deepCopy(defaultDatasetConfig);

  const updateChartData = () => {
    chartData = deepCopy(defaultDatasetConfig);

    if (breakout) {
      const transformed = [...data];

      const groupedData = transformed.reduce((acc, item) => {
        if (!acc[item.x]) {
          acc[item.x] = {};
        }

        if (!acc[item.x][item.z]) {
          acc[item.x][item.z] = 0;
        }

        acc[item.x][item.z] += item.y;
        return acc;
      }, {});

      const xAxis = Object.keys(groupedData);
      const links = [...new Set(transformed.map(({ z }) => z))];

      chartData.datasets = links.map((link) => ({
        label: type === 'time' ? $format('label.TIME') : link,
        data: xAxis.map((key) => groupedData[key][link] || 0),
        backgroundColor: colorMap[link]?.backgroundColor || COLOR_PALETTE[0],
        hoverBackgroundColor: colorMap[link]?.hoverBackgroundColor || COLOR_PALETTE[0].replace('.99', '0.8')
      }));

      chartData.labels = xAxis;
      labels = xAxis;
    } else {
      labels = data.map((item) => item.x || 'Unknown');

      chartData.labels = labels.map((label) => truncate(label));
      chartData.datasets[0].data = data.map((item) => item.y);
      chartData.datasets[0].label = type === 'time' ? $format('label.TIME') : $format('label.TOTAL');
      (chartData.datasets[0].backgroundColor = [...COLOR_PALETTE]),
        (chartData.datasets[0].hoverBackgroundColor = [...COLOR_PALETTE].map((color) => color.replace('.99', '0.8')));

      if (type === 'clicks') {
        chartData.datasets[0].label = $format('label.CLICKS');
        chartData.datasets[0].backgroundColor = ['rgb(255, 99, 132)'];
        chartData.datasets[0].hoverBackgroundColor = ['rgb(255, 99, 102)'];
      }
    }
  };

  const tooltipValueFormatter = (value) => {
    if (type !== 'time') {
      return value;
    }

    return durationFromSeconds(value);
  };

  onMount(updateChartData);

  const getPercentage = (value, total) => ((Number(value) / total) * 100).toFixed();

  $: sum = chartData?.datasets[0]?.data
    ?.filter((item) => typeof item === 'number')
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  $: options = generateChartsConfig(type === 'clicks' ? 'barTime' : 'bar', {
    helpers: {
      tooltipValueFormatter
    },
    scales: {
      y: {
        ticks: {
          callback(value, index, ticks) {
            if (type === 'time') {
              // const val = Math.round(value / 60) * 60;
              const formatted = durationFromSeconds(value);

              if (!ticks.find((item) => item.label === formatted)) {
                return formatted;
              }

              return '';
            }

            if (value % 1 === 0) {
              return value.toLocaleString();
            }
          }
        }
      }
    }
  });

  $: data, updateChartData();
</script>

<div class="chart-wrapper">
  <h3>{title}</h3>
  <div class="chart-container" style="height: {height}; width: {width};">
    {#if loading}
      <div class="loading">
        <Spinner show />
      </div>
    {:else if !data.length}
      <div class="empty">
        <div>
          <i class="links-icon-bar-chart-2" />
          {$format('label.NO_DATA_AVAILABLE')}
        </div>
      </div>
    {:else}
      <Chart type="bar" bind:data={chartData} {options} />
    {/if}
  </div>

  {#if !hideTable && !loading && data.length}
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col" width="20">&nbsp;</th>
          <th scope="col">{heading[type]}</th>
          <th scope="col" class="align-right">
            {#if type === 'users'}
              {$format('label.EVENTS')}
            {:else if type === 'time'}
              {$format('label.TIME')}
            {:else}
              {$format('label.TOTAL')}
            {/if}
          </th>
        </tr>
      </thead>
      <tbody>
        {#each chartData.labels as label, index}
          <tr>
            <td>{index + 1}.</td>
            <td title={labels[index]}>
              <span class="cell">{labels[index]}</span>
            </td>
            <td class="align-right">
              {#if breakout}
                {@const groupsum = chartData?.datasets
                  .flatMap(({ data }) => data)
                  .filter((item) => typeof item === 'number')
                  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)}
                <div class="breakout">
                  {#each chartData.datasets as dataset}
                    {#if chartData.datasets.length > 1 && type !== 'time'}
                      <span class="marker" title={dataset.label} style="background-color: {dataset.backgroundColor};" />
                    {/if}
                    {#if type !== 'time'}
                      <span
                        >{(dataset.data[index] || 0).toLocaleString()}<small
                          >/{getPercentage(dataset.data[index] || 0, groupsum)}%</small
                        ></span
                      >
                    {:else if dataset.data[index] > 0}
                      {durationFromSeconds(dataset.data[index])}
                    {/if}
                  {/each}
                </div>
              {:else if type !== 'time'}
                {(chartData.datasets[0].data[index] || 0).toLocaleString()}<small
                  >/{getPercentage(chartData.datasets[0].data[index] || 0, sum)}%</small
                >
              {:else}
                {durationFromSeconds(chartData.datasets[0].data[index])}
              {/if}
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}
</div>

<style lang="scss" src="./chart.scss"></style>
