<div class="page terms">
  <div class="page-content">
    <h1>Terms of Service</h1>

    <p>
      Welcome to Colinks LLC (“we,” “us,” “our”). These Terms of Service (“Terms”) govern your use of our
      software-as-a-service (SaaS) platform (the “Service”). By accessing or using the Service, you agree to be bound by
      these Terms. If you do not agree with these Terms, you may not use the Service.
    </p>

    <br />

    <h2>1. Eligibility</h2>
    <p>
      By using the Service, you represent and warrant that you have the legal capacity to enter into a binding contract.
    </p>

    <br />
    <br />

    <h2>2. Account Registration</h2>
    <p>To use certain features of the Service, you may be required to create an account. You agree to:</p>

    <br />

    <ul>
      <li>Provide accurate, current, and complete information during registration.</li>
      <li>Maintain the security of your account by keeping your password confidential.</li>
      <li>Notify us immediately of any unauthorized use of your account.</li>
      <li>Be responsible for all activities that occur under your account.</li>
    </ul>

    <br />
    <br />

    <h2>3.Use of the Service</h2>

    <br />

    <h4>3.1. License</h4>
    <p>
      We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Service for
      your internal business purposes, subject to these Terms.
    </p>

    <br />

    <h4>3.2. Restrictions</h4>
    <p>You agree not to:</p>

    <br />

    <ul>
      <li>Use the Service for any illegal or unauthorized purpose.</li>
      <li>
        Modify, adapt, or hack the Service or modify another website to falsely imply association with the Service.
      </li>
      <li>
        Reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without our express written
        permission.
      </li>
    </ul>

    <br />

    <h4>3.3. Service Availability</h4>
    <p>
      We reserve the right to modify, suspend, or discontinue the Service at any time with or without notice. We are not
      liable for any modification, suspension, or discontinuation of the Service.
    </p>

    <br />
    <br />

    <h2>4. Fees and Payment</h2>

    <br />

    <h4>4.1. Subscription Fees</h4>
    <p>
      If you purchase a subscription to the Service, you agree to pay all applicable fees as described during the
      purchase process. Subscription fees are billed in advance on a monthly or annual basis and are non-refundable.
    </p>

    <br />

    <h4>4.2. Taxes</h4>
    <p>
      All fees are exclusive of any applicable taxes. You are responsible for paying all taxes associated with your
      purchase of the Service.
    </p>

    <br />

    <h4>4.3. Late Payments</h4>
    <p>We may suspend or terminate your access to the Service if you fail to pay any fees due.</p>

    <br />
    <br />

    <h2>5. Intellectual Property</h2>
    <p>
      The Service and its original content, features, and functionality are and will remain the exclusive property of
      Colinks LLC and its licensors. The Service is protected by copyright, trademark, and other laws of both your
      country and foreign countries.
    </p>

    <br />
    <br />

    <h2>6. User Content</h2>
    <h4>6.1. Responsibility for Content</h4>
    <p>
      You are solely responsible for any content you submit, post, or display on or through the Service (“User
      Content”). You must ensure that your User Content does not violate any applicable laws or these Terms.
    </p>

    <br />

    <h2>6. Termination</h2>
    <p>
      We may terminate or suspend your account and access to the Service immediately, without prior notice or liability,
      if you breach any of these Terms. Upon termination, your right to use the Service will cease immediately. If you
      wish to terminate your account, you may simply discontinue using the Service.
    </p>

    <br />
    <br />

    <h2>7. Limitation of Liability</h2>
    <p>
      To the fullest extent permitted by law, in no event shall Colinks LLC, its affiliates, directors, employees, or
      licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including without
      limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
    </p>

    <br />

    <ul>
      <li>Your use of or inability to use the Service.</li>
      <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
      <li>Any interruption or cessation of transmission to or from the Service.</li>
    </ul>

    <br />
    <br />

    <h2>8. Disclaimer of Warranties</h2>
    <p>
      The Service is provided on an “AS IS” and “AS AVAILABLE” basis. We disclaim all warranties, express or implied,
      including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and
      non-infringement. We do not warrant that the Service will be uninterrupted, error-free, or completely secure.
    </p>

    <br />
    <br />

    <h2>9. Governing Law</h2>
    <p>
      These Terms shall be governed by and construed in accordance with the laws of the State of California, without
      regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought
      exclusively in the courts of the State of California.
    </p>

    <br />
    <br />

    <h2>10. Changes to These Terms</h2>
    <p>
      We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms
      on this page. Your continued use of the Service after such modifications will constitute your acknowledgment of
      the modified Terms and your agreement to abide by and be bound by them.
    </p>
  </div>
</div>

<style lang="scss" src="./terms.scss"></style>
