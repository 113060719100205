export const isObject = function (item) {
  return Object.prototype.toString.call(item) === '[object Object]';
};

export const merge = function (target, ...sources) {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          });
        }

        merge(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }

  return merge(target, ...sources);
};

export const deepCopy = (obj) => {
  let parsed;

  try {
    parsed = JSON.parse(JSON.stringify(obj));
  } catch (err) {
    parsed = obj;
    console.error(err);
  }

  return parsed;
};

// safely handles circular references
export const stringifyJSON = (obj, indent = 2) => {
  let cache = [];

  const stringified = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // duplicate reference found, discard key
          : cache.push(value) && value // store value in our collection
        : value,
    indent
  );

  cache = null;

  return stringified;
};

export const getNestedPropertyValue = (obj, key) => {
  const keys = key.split('.');

  let value = obj;

  for (const k of keys) {
    value = value[k];

    if (value === undefined) {
      break;
    }
  }

  return value;
};

export const detailedDiff = (obj1, obj2) => {
  if (obj1 === obj2) {
    return null;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    const resultDiff = { added: [], deleted: [], updated: [] };

    const obj1Set = new Set(obj1);
    const obj2Set = new Set(obj2);

    obj1.forEach((item) => {
      if (!obj2Set.has(item)) {
        resultDiff.deleted.push(item);
      }
    });

    obj2.forEach((item) => {
      if (!obj1Set.has(item)) {
        resultDiff.added.push(item);
      }
    });

    obj1.forEach((value1, i) => {
      const value2 = obj2[i];

      if (value1 !== undefined && value2 !== undefined && value1 !== value2) {
        const itemDiff = detailedDiff(value1, value2);
        if (itemDiff) {
          resultDiff.updated.push({ index: i, diff: itemDiff });
        }
      }
    });

    ['added', 'deleted', 'updated'].forEach((category) => {
      if (resultDiff[category].length === 0) {
        delete resultDiff[category];
      }
    });

    return Object.keys(resultDiff).length === 0 ? null : resultDiff;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return {
      updated: obj2
    };
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const allKeys = new Set([...keys1, ...keys2]);

  const resultDiff = { added: {}, deleted: {}, updated: {} };

  allKeys.forEach((key) => {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (!(key in obj1)) {
      resultDiff.added[key] = value2;
      return;
    }

    if (!(key in obj2)) {
      resultDiff.deleted[key] = value1;
      return;
    }

    const keyDiff = detailedDiff(value1, value2);

    if (keyDiff) {
      if (keyDiff.added) {
        resultDiff.added[key] = keyDiff.added;
      }

      if (keyDiff.deleted) {
        resultDiff.deleted[key] = keyDiff.deleted;
      }

      if (keyDiff.updated) {
        resultDiff.updated[key] = keyDiff.updated;
      } else {
        resultDiff.updated[key] = keyDiff;
      }
    }
  });

  ['added', 'deleted', 'updated'].forEach((category) => {
    if (Object.keys(resultDiff[category]).length === 0) {
      delete resultDiff[category];
    }
  });

  return Object.keys(resultDiff).length === 0 ? null : resultDiff;
};

export const groupItemsByProperty = (obj, prop) => {
  const result = {};

  for (const key in obj) {
    const property = obj[key];

    if (!result[property[prop]]) {
      result[property[prop]] = {};
    }

    result[property[prop]][key] = property;
  }

  return result;
};
