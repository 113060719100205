<script>
  import { format } from 'svelte-i18n';
  import { useQuery } from '@sveltestack/svelte-query/svelte';

  import { ActivityModal } from '@client/components/manager/modals';
  import { Button } from '@client/components/button';
  import { FilterBar } from '@client/components/filter-bar';
  import { Grid } from '@client/components/grid';
  import { Loader } from '@client/components/loader';
  import { MultiSelectFilter } from '@client/components/filters/multiselect';
  import { Pagination } from '@client/components/pagination';
  import { Porthole } from '@client/components/porthole';
  import { SearchFilter } from '@client/components/filters/search';
  import { getNotificationsContext } from '@client/components/notifications';

  import { ActionsCell, DetailsCell, ActivityCell, OwnerCell } from './cell-formatters';

  import { UrlsService } from '@client/services/urls';
  import { TagsService } from '@client/services/tags';
  import { UsersService } from '@client/services/users';

  import { formatTimestamp } from '@client/utils/dates';
  import { QueryParams } from '@client/utils/query-params';

  import { appStore } from '@client/stores/app';
  import { userStore } from '@client/stores/user';
  import { urlsSettingsStore } from '@client/stores/page-settings';

  import { ConfirmModal, CreateEditModal } from './modals';

  const { addNotification } = getNotificationsContext();

  const getParamValue = (property, defaultValue, formatter) => {
    const value = QueryParams.get(property) || defaultValue;

    if (formatter) {
      return formatter(value);
    }

    return value;
  };

  let queryParams = {
    field: getParamValue('field', 'viewCount'),
    limit: getParamValue('perpage', 25, Number),
    order: getParamValue('order', -1),
    page: getParamValue('page', 0, (page) => Math.max(0, page - 1)),
    search: getParamValue('search', ''),
    users: getParamValue('users', ''),
    tags: getParamValue('tags', '')
  };

  let filters = {
    users: $urlsSettingsStore.filters.users || [],
    tags: $urlsSettingsStore.filters.tags || []
  };

  let currentUser = {};
  let urls = [];
  let isEditing = false;
  let removeShortUrl = null;
  let showReportModal = false;
  let showConfirmModal = false;
  let showCreateEdit = false;
  let totalRecords = 0;
  let totalPages = 0;
  let initialized = false;
  let tags = [];
  let users = [];
  let showFilters =
    $appStore.isMobile && $urlsSettingsStore.showFilters !== undefined ? $urlsSettingsStore.showFilters : true;

  let urlConfig = {
    originalLink: '',
    originalUrl: '',
    tags: [],
    editors: []
  };

  const remove = ({ shortUrl }) => {
    removeShortUrl = shortUrl;
    showConfirmModal = true;
  };

  const confirmRemoval = async () => {
    const url = urls.find(({ shortUrl }) => shortUrl === removeShortUrl);

    await UrlsService.delete(url);

    queryFn();

    const text = $format('label.NOTIFICATION.URL_DELETE_SUCCESS', {
      values: {
        item: shortUrl.title
      }
    });

    addNotification({
      text,
      header: $format('label.DELETED'),
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });

    removeShortUrl = null;
    showConfirmModal = false;
  };

  const create = () => {
    showCreateEdit = true;
    isEditing = false;
  };

  const edit = async (url) => {
    const response = await UrlsService.get(url.shortHash);

    if (response) {
      urlConfig = { ...response };
      showCreateEdit = true;
      isEditing = true;
    }
  };

  const report = ({ domainHash, shortHash }) => {
    showReportModal = `${domainHash}/${shortHash}`;
  };

  const copy = ({ shortUrl }) => {
    navigator.clipboard.writeText(shortUrl);

    addNotification({
      header: $format('label.COPIED'),
      text: $format('label.NOTIFICATION.URL_COPIED'),
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });
  };

  const reset = () => {
    showConfirmModal = false;
    showReportModal = false;
    showCreateEdit = false;
    isEditing = false;
    removeShortUrl = '';
    urlConfig = {
      originalLink: '',
      originalUrl: '',
      tags: [],
      editors: []
    };
  };

  const onColumnSort = (field, order) => {
    $urlsSettingsStore.params = { ...queryParams, field, order };
  };

  let columns = [
    {
      id: 'title',
      sortKey: 'title',
      label: $format('label.DETAILS'),
      renderComponent: {
        component: DetailsCell,
        props: {
          tags
        }
      }
    },
    {
      id: 'activity',
      label: '',
      sortable: false,
      renderComponent: ActivityCell
    },
    {
      id: 'viewCount',
      class: 'text-align--center',
      sortKey: 'viewCount',
      label: $format('label.VIEWS'),
      renderValue: ({ viewCount }) => viewCount
    },
    {
      id: 'createdBy',
      sortKey: 'createdBy.user',
      label: $format('label.OWNER'),
      renderComponent: OwnerCell
    },
    {
      id: 'updatedAt',
      sortKey: 'updatedAt',
      label: $format('label.UPDATED'),
      renderValue: ({ updatedAt }) => formatTimestamp(updatedAt, 'MM/dd/yyyy @ hh:mm a')
    },
    {
      id: 'actions',
      label: '',
      renderComponent: {
        component: ActionsCell,
        props: {
          edit,
          remove,
          report,
          copy,
          currentUser: $userStore
        }
      }
    }
  ];

  const onChangePage = (page) => {
    $urlsSettingsStore.params = { ...queryParams, page };
  };

  const onChangeRecords = (limit) => {
    $urlsSettingsStore.params = { ...queryParams, limit };
  };

  const updateQueryParams = () => {
    queryParams = { ...queryParams };
    $urlsSettingsStore.params = queryParams;
  };

  const createUsersFilter = async () => {
    const { records } = await UsersService.getAll();

    const items = records.map(({ name, user, ...rest }) => ({
      label: name,
      value: user,
      ...rest
    }));

    users = items.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    filters.users = users.slice();

    users = users.filter(({ value }) => value !== currentUser.user);

    $urlsSettingsStore.filters = filters;
  };

  const createTagsFilter = () => {
    const items = tags.map(({ label, ...rest }) => ({
      label,
      value: label,
      ...rest
    }));

    tags = items.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });

    filters.tags = tags.slice();

    $urlsSettingsStore.filters = filters;
  };

  const queryFn = async () => {
    const { pagination, records } = await UrlsService.getAll({ ...queryParams });

    const getTags = await TagsService.getAll();
    tags = getTags.records;
    columns[0].renderComponent.props.tags = tags.reduce((acc, value) => (acc[value.label] = value) && acc, {});

    createTagsFilter();

    totalRecords = pagination.total;
    totalPages = pagination.pages;
    urls = records;

    initialized = true;

    return urls;
  };

  createUsersFilter();

  const query = useQuery(['shorturls', queryParams], queryFn, { keepPreviousData: true });

  $: if (!showCreateEdit) {
    urlConfig = {
      originalLink: '',
      originalUrl: '',
      tags: [],
      editors: []
    };
  }

  $: updateQueryParams(queryParams);
  $: query.updateOptions({ queryKey: ['shorturls', queryParams], queryFn });
  $: currentUser = { ...$userStore };
  $: $urlsSettingsStore.showFilters = showFilters;
</script>

<div class="page with-footer links">
  <Loader backdrop show={$query.isLoading || $query.isFetching} />

  {#if initialized}
    <div class="page-header">
      <div class="row">
        <div class="col-md-12">
          <h1>
            {$format('label.MANAGE_SHORT_URLS')}
          </h1>
          <Button on:click={create}>
            {#if $appStore.isMobile}
              {$format('label.NEW')}
            {:else}
              {$format('label.NEW_SHORT_URL')}
            {/if}
          </Button>
        </div>
      </div>
    </div>
    <div class="page-content" class:filters-shown={showFilters}>
      <div class="top">
        <FilterBar bind:show={showFilters}>
          <SearchFilter bind:search={queryParams.search} />
          {#if filters.users.length > 1}
            <MultiSelectFilter
              bind:items={filters.users}
              bind:selectedValues={queryParams.users}
              label={$format('label.USERS')}
              menuHeight="300px"
              param="users"
              search
              tooltipLabel={$format('label.SELECTED_OWNERS')}
            >
              <svelte:fragment slot="label" let:item>
                <div class="item">
                  <Porthole details={item} color={item.color} size="small" />
                  <div class="item-detail">
                    <div class="name">{item.label}</div>
                    <div class="email">{item.email}</div>
                  </div>
                </div>
              </svelte:fragment>
            </MultiSelectFilter>
          {/if}

          {#if filters.tags.length > 1}
            <MultiSelectFilter
              bind:items={filters.tags}
              bind:selectedValues={queryParams.tags}
              label={$format('label.TAGS')}
              menuHeight="300px"
              param="tags"
              search
              tooltipLabel={$format('label.SELECTED_TAGS')}
            >
              <svelte:fragment slot="label" let:item>
                <div class="item">
                  <div class="item-detail">
                    <span style="background: {item.bg}; color: {item.color};">{item.label}</span>
                  </div>
                </div>
              </svelte:fragment>
            </MultiSelectFilter>
          {/if}
        </FilterBar>
      </div>

      <Grid
        className="urls-grid"
        bind:columns
        bind:rows={urls}
        bind:sortKey={queryParams.field}
        bind:sortOrder={queryParams.order}
        onSort={onColumnSort}
      />
    </div>
    <div class="page-footer">
      {#if urls.length}
        <Pagination
          bind:currentPage={queryParams.page}
          bind:perPage={queryParams.limit}
          bind:totalRecords
          bind:totalPages
          {onChangeRecords}
          {onChangePage}
        />
      {/if}
    </div>
  {/if}
</div>

{#if showCreateEdit}
  <CreateEditModal
    bind:show={showCreateEdit}
    bind:urlConfig
    bind:editing={isEditing}
    bind:editors={users}
    bind:currentUser
    {tags}
    onSave={queryFn}
    onClose={reset}
  />
{/if}

{#if showConfirmModal}
  <ConfirmModal bind:show={showConfirmModal} bind:shortUrl={removeShortUrl} onSave={confirmRemoval} onClose={reset} />
{/if}

{#if showReportModal}
  <ActivityModal bind:show={showReportModal} link={showReportModal} onClose={reset} />
{/if}

<style lang="scss" src="./urls.scss"></style>
