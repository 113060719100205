export const calculateTimeSaved = (templateText, averageWPM = 40) => {
  // Calculate the number of words in the template text
  const wordCount = templateText.trim().split(/\s+/).length;

  // Calculate the time to type the words manually in minutes
  const timeToType = wordCount / averageWPM;

  // Convert time from minutes to seconds
  const timeToTypeInSeconds = timeToType * 60;

  return timeToTypeInSeconds;
};
