<script>
  export let color = '#FF7B00';

  let focus = false;

  const updateColor = function () {
    color = this.value;
  };

  const onFocus = () => (focus = true);
  const onBlur = () => (focus = false);
</script>

<div class="color-picker" class:focus>
  <input
    type="color"
    {...$$restProps}
    class="color-selection"
    bind:value={color}
    on:input={updateColor}
    on:focus={onFocus}
    on:blur={onBlur}
  />
  <input
    type="text"
    class="color-preview"
    bind:value={color}
    on:input={updateColor}
    on:focus={onFocus}
    on:blur={onBlur}
  />
</div>

<style lang="scss" src="./color-picker.scss"></style>
