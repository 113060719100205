<script>
  import { Spinner } from '@sveltestrap/sveltestrap';
  /**
   * Indicates whether the backdrop is enabled for the component.
   * @type {boolean}
   * @default false
   */
  export let backdrop = false;

  /**
   * Additional CSS class names to apply to the component.
   * @type {string}
   * @default ''
   */
  export let className = '';

  /**
   * Determines if the component should be displayed inline.
   * @type {boolean}
   * @default false
   */
  export let inline = false;

  /**
   * Controls the visibility of the component.
   * @type {boolean}
   * @default false
   */
  export let show = false;

  /**
   * Inline styles applied to the component, specifying CSS properties.
   * @type {string}
   * @default 'border-width: 2px; color: #0087ff; height: 24px; width: 24px;'
   */
  export let styles = 'border-width: 2px; color: #0087ff; height: 24px; width: 24px;';
</script>

{#if show}
  <div class="spinner {className}" class:backdrop class:inline>
    <Spinner color="primary" style={styles} />
  </div>
{/if}

<style lang="scss">
  .spinner {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;

    &.inline {
      position: relative;
    }

    &.backdrop {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
</style>
