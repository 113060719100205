<script>
  import { format } from 'svelte-i18n';
  import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '@sveltestrap/sveltestrap';

  import { QueryParams } from '@client/utils/query-params';

  export let filter = {};
  export let onClick = () => {};
  export let sortKeyParam = null;
  export let sortDirParam = null;
  export let sortDirection = -1;

  export let autoClose = true;
  export let maxHeight = 'auto';

  let isOpen = false;
  let selected = null;

  const onClickHandler = (e, item) => {
    selected = item;

    Object.keys(filter).forEach((name) => (filter[name].enabled = filter[name].value === item.value));

    filter = { ...filter };

    onClick(selected, item.value, sortDirection);
  };

  const updateQueryParams = () => {
    if (selected) {
      QueryParams.set(sortKeyParam, selected.value);
      QueryParams.set(sortDirParam, sortDirection);
    } else {
      QueryParams.delete(sortKeyParam);
      QueryParams.delete(sortDirParam);
    }
  };

  const onChangeSortDirection = (e, dir) => {
    e.preventDefault();
    e.stopPropagation();

    sortDirection = dir;

    updateQueryParams();

    onClick(selected, selected.value, sortDirection);

    return false;
  };

  Object.keys(filter).forEach((name) => {
    if (filter[name].enabled) {
      selected = filter[name];
    }
  });

  $: sortKeyParam && updateQueryParams(selected);
</script>

<Dropdown {isOpen} {autoClose} toggle={() => (isOpen = !isOpen)}>
  <DropdownToggle tag="div">
    {#if selected}
      <div class="filter-button has-value">
        <span>{$format(selected.label)}</span>
        <span class="split" />
        <i
          class="links-icon-arrow-up"
          class:active={sortDirection === 1}
          on:click={(e) => onChangeSortDirection(e, 1)}
          role="button"
          tabindex="0"
        />
        <i
          class="links-icon-arrow-down"
          class:active={sortDirection !== 1}
          on:click={(e) => onChangeSortDirection(e, -1)}
          role="button"
          tabindex="0"
        />
      </div>
    {:else}
      <button class="filter-button">{$format('label.SORT_BY')}</button>
    {/if}
  </DropdownToggle>
  <DropdownMenu style="max-height: {maxHeight}; overflow: auto;">
    {#each Object.entries(filter) as [value, config]}
      <DropdownItem on:click={(e) => onClickHandler(e, config)} class={config.enabled ? 'active' : ''}>
        {$format(config.label)}
      </DropdownItem>
    {/each}
  </DropdownMenu>
</Dropdown>

<style lang="scss" src="./sort.scss"></style>
