import { APITransport } from '@client/transports/api';

export const TagsService = {
  getAll(query = {}) {
    return APITransport.get({
      path: '/v1/tags',
      query
    });
  },

  get(tag) {
    return APITransport.get({
      path: `/v1/tags/${tag}`
    });
  },

  create(body) {
    return APITransport.post({
      path: `/v1/tags`,
      body
    });
  },

  delete({ label, links = [], tags = [] }) {
    return APITransport.delete({
      path: `/v1/tags/${label}`,
      body: { links, tags }
    });
  },

  update({ label, ...rest }) {
    return APITransport.put({
      path: `/v1/tags/${label}`,
      body: { label, ...rest }
    });
  }
};
