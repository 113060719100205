<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';

  export let nextStep = () => {};
</script>

<div class="wizard-page">
  <h1>{$format('label.WELL_HELLO_THERE')}</h1>
  <img src="/images/welcome.svg" alt="Create new link" />
  <p>{$format('label.GET_STARTED_FIRST_LINK')}</p>
  <Button on:click={nextStep}>
    {$format('label.OK_LETS_GO')}
  </Button>
</div>

<style lang="scss" src="../getting-started.scss"></style>
