<div class="page privacy">
  <div class="page-content">
    <h1>Privacy Policy</h1>

    <p>
      Colinks LLC ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we
      collect, use, disclose, and safeguard your information when you use our software-as-a-service (SaaS) platform (the
      "Service"). Please read this policy carefully to understand our practices regarding your information and how we
      will treat it.
    </p>

    <br />

    <h2>1. Information We Collect</h2>
    <h3>1.1. Personal Information</h3>

    <p>
      When you use our Service, we may collect personally identifiable information ("Personal Information") such as:
    </p>

    <br />

    <ul>
      <li>Name</li>
      <li>Email address</li>
      <li>Contact information</li>
      <li>Billing information</li>
    </ul>

    <br />

    <h3>1.2. Non-Personal Information</h3>

    <p>We may also collect non-personal information that cannot be used to identify you, such as:</p>

    <br />

    <ul>
      <li>Browser type</li>
      <li>Device information</li>
      <li>IP address</li>
      <li>Usage data (e.g., pages viewed, actions taken)</li>
    </ul>

    <br />

    <h3>1.3. Cookies and Tracking Technologies</h3>
    <p>
      We use cookies and similar tracking technologies to track the activity on our Service and hold certain
      information. Cookies are files with small amounts of data which may include an anonymous unique identifier. You
      can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
      accept cookies, you may not be able to use some portions of our Service.
    </p>

    <br />
    <br />

    <h2>2. How We Use Your Information</h2>
    <p>We may use the information we collect for various purposes, including:</p>

    <br />

    <ul>
      <li>To provide and maintain our Service</li>
      <li>To process transactions</li>
      <li>
        To communicate with you, including sending updates, marketing materials, and service-related notifications
      </li>
      <li>To personalize your experience</li>
      <li>To improve our Service</li>
      <li>To comply with legal obligations</li>
    </ul>

    <br />
    <br />

    <h2>3. Sharing Your Information</h2>
    <p>
      We do not sell, trade, or rent your Personal Information to others. However, we may share your information with
      third parties in the following situations:
    </p>

    <ul>
      <li>
        With service providers who assist us in operating our Service, provided that they agree to keep this information
        confidential.
      </li>
      <li>For legal reasons, if we are required to disclose your information by law, regulation, or legal process.</li>
      <li>In the event of a merger or acquisition, where your information may be transferred as part of the assets.</li>
    </ul>

    <br />
    <br />

    <h4>Additional Information for Californian Consumers</h4>

    <p>
      The California Consumer Privacy Act (Assembly Bill 375),as amended ("CCPA"), affords Californian consumers
      specific rights regarding their personal information. These rights have been described in this Privacy Policy and
      are further highlighted in this section.
    </p>

    <br />

    <p>If you need to access this notice in an alternative format, please contact us at support@colinks.io.</p>

    <br />

    <p>Do Not Sell My Info</p>

    <br />

    <p>
      <b
        >We do not, and will not, sell – as defined under CCPA – your personal information (see below for further info).</b
      >
    </p>

    <br /><br />

    <h4>What is Personal Information under CCPA</h4>

    <p>
      "Personal information" is anything that identifies, relates to, describes, or is capable of being associated with,
      or could reasonably be linked, directly or indirectly, with a particular Californian consumer or household.
    </p>

    <br />
    <br />

    <h2>4. Data Security</h2>
    <p>
      We implement a variety of security measures to maintain the safety of your Personal Information. However, no
      method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its
      absolute security.
    </p>

    <br />
    <br />

    <h2>5. Your Data Protection Rights</h2>
    <p>Depending on your location, you may have the following rights regarding your Personal Information:</p>

    <br />

    <ul>
      <li>The right to access – You have the right to request copies of your Personal Information.</li>
      <li>
        The right to rectification – You have the right to request that we correct any information you believe is
        inaccurate or complete information you believe is incomplete.
      </li>
      <li>
        The right to erasure – You have the right to request that we erase your Personal Information, under certain
        conditions.
      </li>
      <li>
        The right to restrict processing – You have the right to request that we restrict the processing of your
        Personal Information, under certain conditions.
      </li>
      <li>
        The right to object to processing – You have the right to object to our processing of your Personal Information,
        under certain conditions.
      </li>
      <li>
        The right to data portability – You have the right to request that we transfer the information that we have
        collected to another organization, or directly to you, under certain conditions.
      </li>
    </ul>

    <br />
    <br />

    <h2>6. Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
      Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
    </p>

    <br />
    <br />

    <h2>7. Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@colinks.io"
        >support@colinks.io</a
      >.
    </p>
  </div>
</div>

<style lang="scss" src="./privacy.scss"></style>
