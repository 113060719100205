<script>
  import { link } from 'svelte-navigator';
  import { Porthole } from '@client/components/porthole';

  export let row = {};
</script>

<div class="user">
  <Porthole details={row} size="small" />
  <a href="links?users={row.user}" use:link>
    {row.name}
  </a>
</div>

<style lang="scss">
  .user {
    align-items: center;
    display: flex;
    gap: 16px;
  }
</style>
