<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';
  import { Modal } from '@client/components/modal';

  export let show = false;
  export let shortUrl = '';
  export let onSave = function () {};
  export let onClose = function () {};
</script>

<Modal bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>{$format('label.CONFIRM')}</h1>
    </div>
    <div class="modals--content">
      <p class="action-text">
        {@html $format('label.ARE_YOU_SURE_REMOVE', {
          values: {
            item: shortUrl
          }
        })}
      </p>
    </div>
    <div class="modals--footer">
      <Button className="mr-md" on:click={onSave}>{$format('label.CONTINUE')}</Button>
      <Button kind="link" on:click={onClose}>{$format('label.CANCEL')}</Button>
    </div>
  </div>
</Modal>

<style lang="scss" src="./confirm.scss"></style>
