<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';
  import { Chart } from '@client/components/charts';
  import { Loader } from '@client/components/loader';
  import { Modal } from '@client/components/modal';

  import { ReportsService } from '@client/services/reports';
  import { UsersService } from '@client/services/users';

  import { generateDatesInRange } from '@client/utils/dates';

  export let link = null;
  export let show = false;
  export let onClose = () => {};

  let data = {};
  let count = 0;
  let options = {};
  let userCache = {};
  let users = [];

  const cacheUserData = async () => {
    if (users.length) {
      for (let i = 0, k = users.length; i < k; i++) {
        const people = new Set(users[i]);

        if (!people.size) {
          continue;
        }

        const peeps = Array.from(people);

        for (let j = 0, l = peeps.length; j < l; j++) {
          const user = peeps[j];

          if (userCache[user]) {
            continue;
          }

          const { records } = await UsersService.getAll({ searchField: 'user', search: user });
          userCache[user] = records[0];
        }
      }
    }
  };

  const footer = (tooltipItems) => {
    const usersFromPoint = Array.from(new Set(users[tooltipItems[0].dataIndex])).sort();
    const imageOffsetLeft = 16;
    const imageInlineStyle =
      'bottom: -32px; border-radius: 100%; border: 1px solid rgba(255,255,255,.75); max-width: 24px; position: absolute;';

    return (usersFromPoint || [])
      .map((user, index) => {
        if (userCache[user]) {
          return `<img src="${userCache[user].imageUrl}" style="left: ${
            imageOffsetLeft * index
          }px; ${imageInlineStyle} z-index: ${index};" />`;
        }

        return '';
      })
      .join('');
  };

  let fetchingReportData = false;

  const fetchReportData = async () => {
    fetchingReportData = true;

    const views = await ReportsService.getViewReport({ report: 'history', link, days: 1000 });
    const labels = [];
    const records = [];

    users = [];

    if (!views.length) {
      fetchingReportData = false;
      return;
    }

    const dates = views.reduce((acc, view) => {
      acc[view._id] = view;
      return acc;
    }, {});

    const last30days = new Date(new Date().setDate(new Date().getDate() - 30));
    const startDate = new Date(views[0]._id) < last30days ? new Date(views[0]._id) : last30days;
    const endDate = new Date(new Date().setDate(new Date().getDate() + 1));
    const days = generateDatesInRange(startDate, endDate);

    let yAxisMax = 0;

    days.forEach((day) => {
      if (dates[day]) {
        records.push(dates[day].total);
        count += dates[day].total;
        users.push(dates[day].users);
        yAxisMax = Math.max(yAxisMax, dates[day].total);
      } else {
        records.push(0);
        users.push('');
      }

      labels.push(day);
    });

    await cacheUserData();

    data = {
      labels,
      datasets: [
        {
          label: $format('label.VIEWS'),
          data: records,
          fill: true,
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, '#f3e3ff');
            gradient.addColorStop(1, '#e3e3ff');

            return gradient;
          },
          tension: 0.1,
          borderColor: 'rgb(0, 135, 255)'
        }
      ]
    };

    options = {
      plugins: {
        tooltip: {
          callbacks: {
            footer
          }
        }
      },
      elements: {
        point: {
          radius: 0
        }
      },
      scales: {
        x: {
          type: 'time',
          position: 'bottom',
          time: {
            parser: 'yyyy-MM-dd',
            displayFormats: {
              day: 'M/d',
              month: 'MMM'
            },
            tooltipFormat: 'MMMM d, yyyy',
            unit: 'day',
            round: 'day'
          },
          grid: {
            display: false
          },
          ticks: {
            maxRotation: 0,
            stepSize: 10
          }
        },
        y: {
          beginAtZero: true,
          max: yAxisMax + 1,
          grid: {
            display: true
          },
          ticks: {
            display: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value.toLocaleString();
              }
            }
          }
        }
      }
    };

    fetchingReportData = false;
  };

  $: link && fetchReportData(link);
  $: if (!show) {
    data = {
      datasets: []
    };

    count = 0;
  }
</script>

<Modal maxWidth="60%" minWidth="380px" bind:show>
  <div class="modals--content">
    <Loader show={fetchingReportData} />
    <Chart
      type="line"
      label={`co/${link}`}
      title={$format('label.VIEW_ACTIVITY_ALL_TIME')}
      subtitle={$format('label.X_VIEWS_TOTAL', {
        values: { count }
      })}
      bind:data
      bind:options
    />
    <div class="actions">
      <Button kind="primary" on:click={onClose}>Close</Button>
    </div>
  </div>
</Modal>

<style lang="scss" src="./activity.scss"></style>
