<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';
  import { Modal } from '@client/components/modal';
  import { getNotificationsContext } from '@client/components/notifications';

  import { UsersService } from '@client/services/users';

  const { addNotification } = getNotificationsContext();

  export let show = false;
  export let user = {};
  export let isUpdating = false;
  export let onSave = function () {};
  export let onClose = function () {};

  const onConfirm = async () => {
    isUpdating = true;

    const response = await UsersService.delete(user);

    isUpdating = false;

    if (response?.status >= 200) {
      return addNotification({
        header: $format('label.ERROR'),
        text: $format('label.NOTIFICATION.USER_DELETE_FAILURE', { values: { item: user.name } }),
        type: 'error',
        autohide: 3000,
        position: 'top-right'
      });
    }

    addNotification({
      header: $format('label.DELETED'),
      text: $format('label.NOTIFICATION.USER_DELETE_SUCCESS', { values: { item: user.name } }),
      type: 'success',
      autohide: 3000,
      position: 'top-right'
    });

    onSave(true);

    onClose();
  };
</script>

<Modal bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>{$format('label.CONFIRM')}</h1>
    </div>
    <div class="modals--content">
      <p class="action-text">
        {@html $format('label.ARE_YOU_SURE_REMOVE', {
          values: {
            item: user.name
          }
        })}
      </p>
    </div>
    <div class="modals--footer">
      <Button className="mr-md" on:click={onConfirm}>{$format('label.CONTINUE')}</Button>
      <Button kind="link" on:click={onClose}>{$format('label.CANCEL')}</Button>
    </div>
  </div>
</Modal>

<style lang="scss" src="./delete.scss"></style>
