<script>
  import { format } from 'svelte-i18n';
  import { tooltip } from '@svelte-plugins/tooltips';

  export let row = {};
  export let currentUser = {};
  export let onLinkClick = () => {};
  export let addBookmark = () => {};
  export let removeBookmark = () => {};
  export let prefix = 'co';

  const onClick = () => onLinkClick(row.link);
</script>

<div class="link-name">
  {#if !currentUser?.favorites?.has(row.link)}
    <button
      class="links-icon-star"
      on:click={() => addBookmark(row.link)}
      use:tooltip={{
        position: 'right',
        content: `<div style="white-space: nowrap;">${$format('label.ADD_TO_FAVORITES')}</div>`
      }}
    />
  {:else}
    <button
      class="links-icon-star-filled"
      on:click={() => removeBookmark(row.link)}
      use:tooltip={{
        position: 'right',
        content: `<div style="white-space: nowrap;">${$format('label.REMOVE_FROM_FAVORITES')}</div>`
      }}
    />
  {/if}
  <a href={row.source} on:click={onClick} target="_blank">{prefix}/{row.link}</a>
</div>

<style lang="scss">
  .link-name {
    align-items: center;
    display: flex;
    gap: var(--margin-base);
    min-width: 180px;

    [class*='links-icon-'] {
      font-size: var(--font-size-large);
    }

    .links-icon-star-filled {
      color: var(--color-yellow-300);
    }
  }
</style>
